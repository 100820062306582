@import "../../shared.scss";

.login-page {
  width: 100%;
  height: 100vh;
  min-height: $minFullScreenHeight;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: $headerHeight;
  background-color: #0000000a;

  // &:before{
  //     position: absolute;
  //     top: 0;
  //     left: 0;
  //     width: 50%;
  //     height: 4rem;
  //     background: url(../../Assets/stripe-background.svg);
  //     content: "";
  // }

  // &:after{
  //     position: absolute;
  //     bottom: 0;
  //     right: 0;
  //     width: 50%;
  //     height: 4rem;
  //     background: url(../../Assets/stripe-background.svg);
  //     content: "";
  // }

  > button {
    position: absolute;
    top: 1.5rem;
    right: 2.5rem;
    padding: 0;
    width: 2.5rem;
    height: 2.5rem;
    min-width: 2.5rem;
    min-height: 2.5rem;
    max-width: 2.5rem;
    max-height: 2.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 1.5rem;
    background-color: white;
    outline: none;
    border: none;
    box-shadow: 0 0.3rem 0.3rem #00000020;
    cursor: pointer;

    > svg {
      width: 50%;
      height: 50%;

      * {
        fill: black;
        stroke: black;
      }
    }
  }

  > .inner {
    width: 30rem;
    height: 100%;
    margin-top: 10%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color: white;
    padding: 0 2rem;

    &:before {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 4rem;
      background: url(../../Assets/stripe-background.svg);
      content: "";
    }

    &:after {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 4rem;
      background: url(../../Assets/stripe-background.svg);
      content: "";
    }

    > img {
      height: 8rem;
      width: auto;
      object-fit: contain;
      object-position: center;
      margin-bottom: 2rem;
    }

    > h1 {
      font-family: $tertiaryFont;
      font-size: 4.5rem;
      color: $secondaryColor;
      margin: 0 0 3rem;
      font-weight: normal;
    }

    > p {
      font-family: $primaryFont;
      font-size: 1.3rem;
      color: black;
      opacity: 0.5;
      margin: 0 0 2rem;
      text-align: center;
      line-height: 1.2;
    }

    > .row {
      display: flex;
      flex-direction: row;

      > a {
        width: 4rem;
        height: 4rem;
        outline: none;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin: 0 0.75rem;
        text-decoration: none;

        &.facebook {
          border: 1px solid #3b5998;
          background-color: #3b5998;
        }

        &.google {
          border: 1px solid #d9d9d9;
          background-color: white;
        }
      }
    }
  }

  @include low-width {
    min-height: initial;

    > .inner {
      width: 100%;
      padding: 0 5rem;

      &::before,
      &::after {
        height: 8rem;
      }

      > h1 {
        font-size: 6.5rem;
        margin-bottom: 5rem;
      }

      > p {
        font-size: 2.3rem;
        margin-bottom: 5rem;
      }

      > .row {
        > a {
          width: 6rem;
          height: 6rem;
          min-width: 6rem;
          min-height: 6rem;
          max-width: 6rem;
          max-height: 6rem;
        }
      }
    }
  }
}
