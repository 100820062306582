@import '../../../shared.scss';

.image-gallery{
    width: 100%;
    height: auto;
    min-height: $minFullScreenHeight;
    padding-top: $headerHeight + 8rem;
    background: url(../../../Assets/spotted-background.png);
    background-size: 100% auto;
    background-repeat: no-repeat repeat;

    > .title{
        display: flex;
        flex-direction: column;
        width: 80%;
        max-width: $innerMaxWidth * 0.8;
        margin: 0 auto 5rem;
        align-items: center;

        > h1{
            width: 80%;
            text-align: center;
            font-family: $primaryFont;
            font-size: 2.5rem;
            background: url(../../../Assets/stripe-background.svg);
            padding: 0.8rem 0 0.5rem;
            margin: 0;
            color: $secondaryColor;
            font-weight: normal;
            letter-spacing: 0.05rem;
        }

        > p.text{
            width: 100%;
            padding: 1.5rem 3rem;
            text-align: center;
            font-family:$secondaryFont;
            font-size: 1rem;
            color: black;
            margin: 0;
            background: white;
            box-shadow: 0 0.5rem 0.5rem #00000010;
        }
    }

    .gallery{
        width: 100%;
        max-width: $innerMaxWidth;
        margin: 0 auto 10rem;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        flex-wrap: wrap;

        > img{
            width: calc(25% - 0.4rem);
            position: relative;
            z-index: 0;
            height: 13rem;
            object-fit: cover;
            object-position: center;
            margin-right: 0.4rem;
            margin-bottom: 0.4rem;
            transition: transform 0.2s ease;
            cursor: pointer;

            &:hover{
                transform: scale(1.1);
                z-index: 1;
            }

            &:nth-child(4n){
                transform-origin: 100% 50%;
                margin-right: 0;
            }

            &:nth-child(4n + 1){
                transform-origin: 0% 50%;
            }

            &:nth-child(4n + 2){
                transform-origin: 33% 50%;
            }

            &:nth-child(4n + 3){
                transform-origin: 66% 50%;
            }

        }
    }

    @include low-width{
        > .title{
            > h1{
                font-size: 3.5rem;
                line-height: 1.2;
            }

            > p.text{
                font-size: 1.5rem;
                padding: 2.5rem 2rem;
            }
        }

        > .gallery{
            flex-direction: column;

            > img{
                width: calc(100% - 2rem);
                height: auto;
                margin: 0 auto 3rem;

                &:nth-child(4n){
                    margin: 0 auto 3rem;
                }
                
                &:hover{
                    transform: none;
                }

            }
        }
        
    }
}