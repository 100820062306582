@import '../../shared.scss';

.ticket_iframe_container{
    width: 100%;
    height: 98%;
    margin: 1% 0;
    background-color: white;
    display: flex;

    > iframe{
        width: 100%;
        height: 100%;
    }
}

.tickets{
    width: 100%;
    height: auto;
    min-height: $minFullScreenHeight;

    > p.mini-banner{
        width: 100%;
        height: auto;
        position: relative;
        font-family: $secondaryFont;
        margin: 0;
        font-size: 1rem;
        color: white;
        background-color: $primaryColor;
        padding: 1rem 0;
        text-align: center;
        transition: margin 0.2s ease;
        margin: var(--headerHeight) 0 0;

        &.invisible{
            margin-top: calc(var(--headerHeight) - 4rem);
        }
        
        > svg{
            cursor: pointer;
            position: absolute;
            height: 1rem;
            width: auto;
            top: 1rem;
            right: 3rem;
        }
    }

    > .section-1{
        width: 100%;
        height: 100vh;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content:flex-start;

        &:after{
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            content: "";
            // background: linear-gradient(rgba(black, 0), rgba(black, 0.9));
            pointer-events: none;
        }

        > img, video {
            width: 100%;
            height: 100%;
            min-width: 100%;
            min-height: 100%;
            max-width: 100%;
            max-height: 100%;
            position: absolute;
            object-fit: cover;
            object-position: center;
            z-index: 0;
            top: 0;
            left: 0;
        }

        > p{
            position: relative;
            z-index: 2;
            font-family: $primaryFont;
            font-size: 2.3rem;
            color: white;
            padding: 0;
            letter-spacing: 0.1rem;
            margin: 0;
            
            &::before{
                width: 90%;
                height: 0;
                position: absolute;
                top: 0;
                left: 50%;
                transform: translateX(-50%);
                border-top: 1px solid $primaryColor;
                content: "";
            }
        }
    }

    > .section-2{
        width: 100%;
        height: auto;
        padding: 10rem 0;
        background: url(../../Assets/spotted-background.png);
        background-size: 100% auto;
        background-repeat: no-repeat repeat;

        > .item{
            width: 100%;
            max-width: $innerMaxWidth;
            margin: 0 auto 8rem;
            background-color: white;
            box-shadow: 0 0.1rem 1rem #00000010;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 0 0 3rem;

            > h3{
                width: 50%;
                font-family: $tertiaryFont;
                font-size: 2rem;
                color: $secondaryColor;
                padding: 1rem 0;
                text-align: center;
                font-weight: normal;
                margin: 0 auto 5rem;
                background: url(../../Assets/stripe-background.svg);
            }

            > .main{
                width: 100%;
                display: flex;
                flex-direction: row;
                margin-bottom: 5rem;

                > .item{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    width: 50%;

                    &:first-child{
                        border-right: 1px solid #70707030;
                    }

                    &.single{
                        width: 100%;

                        &:first-child{
                            border-right: none;
                        }
                    }

                    > .team{
                        display: flex;
                        flex-direction: column;
                        align-items: center;

                        > img{
                            width: 5rem;
                            height: 5rem;
                            object-fit: contain;
                            object-position: center;
                            margin-bottom: 2rem;
                        }

                        > p{
                            font-family: $tertiaryFont;
                            font-size: 1rem;
                            color: #9E9E9E;
                            margin: 0;
                        }
                    }

                    > .time{
                        font-family: $tertiaryFont;
                        font-size: 1.7rem;
                        color: $secondaryColor;
                        padding: 0.8rem 1.2rem;
                        text-align: center;
                        font-weight: normal;
                        margin: 0 2rem;
                        background: url(../../Assets/stripe-background.svg);
                        border-radius: 0.5rem;
                    }
                    
                }
            }

            > .extra{
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-evenly;
                align-items: center;

                > .time{
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    > svg{
                        width: auto;
                        max-width: 2rem;
                        height: 2rem;
                        margin-right: 2rem;
                    }
                    
                    > p{
                        font-family: $primaryFont;
                        font-size: 1.5rem;
                        color: $primaryColor;
                        text-align: start;
                        margin: 0;
                    }
                }

                > button{
                    background-color: $primaryColor;
                    padding: 1rem 4rem;
                    border: none;
                    outline: none;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    text-decoration: none;
                    border-radius: 0.5rem;
                    cursor: pointer;

                    > img{
                        height: 2.5rem;
                        width: auto;
                        margin-right: 1rem;
                    }

                    p{
                        font-family: $primaryFont;
                        font-size: 1.2rem;
                        color: white;
                        margin: 0;
                    }
                }

                > .venue{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    
                    > svg{
                        width: auto;
                        max-width: 2rem;
                        height: 2rem;
                        margin-right: 2rem;
                    }

                    > p{
                        font-family: $primaryFont;
                        font-size: 1.5rem;
                        color: $secondaryColor;
                        text-align: start;
                        margin: 0;
                    }
                }
            }
        }
    }

    @include low-width{
        > .mini-banner{
            font-size: 1.5rem;
            padding: 1.5rem 0;
        }

        > .section-1{
            height: 65rem;

            > p{
                font-size: 2.8rem;
                letter-spacing: 0.2rem;
                padding: 2rem 0 4rem;
            }
        }

        > .section-2{
            padding: 10rem 2rem;

            > .item{
                box-shadow: 0 1rem 3rem #00000020;

                > h3{
                    width: 100%;
                    font-size: 4rem;
                    padding: 2rem 0;
                    margin-bottom: 0;
                }

                > .main{
                    flex-direction: column;
                    align-items: center;
                    margin-bottom: 0;

                    > .item{
                        width: 100%;
                        justify-content: space-evenly;
                        padding: 5rem 0;
                        position: relative;

                        &:first-child{
                            border-right: none;

                            &::after{
                                content: "";
                                width: 50%;
                                height: 0;
                                position: absolute;
                                bottom: 0;
                                left: 50%;
                                transform: translateX(-50%);
                                border-bottom: 1px solid #70707030;
                            }
                        }

                        > .team{
                            > img{
                                width: 8rem;
                                height: 8rem;
                            }

                            > p{
                                font-size: 1.5rem;
                            }
                        }

                        > .time{
                            font-size: 2.5rem;
                            padding: 1rem 2rem;
                            margin: 0;
                        }
                    }
                }

                > .extra{
                    margin-bottom: 15rem;
                    position: relative;
                    $svgDim: 3rem;

                    > .time{
                        flex-direction: column;
                        align-items: center;

                        > svg{
                            width: $svgDim;
                            height: $svgDim;
                            min-width: $svgDim;
                            min-height: $svgDim;
                            max-width: $svgDim;
                            max-height: $svgDim;
                            margin-right: 0;
                            margin-bottom: 1rem;
                        }

                        > p{
                            text-align: center;
                            font-size: 2rem;
                        }
                    }

                    > button{
                        position: absolute;
                        top: 100%;
                        left: 50%;
                        transform: translateX(-50%);
                        margin-top: 6rem;
                    }

                    > .venue{
                        flex-direction: column;
                        align-items: center;

                        > svg{
                            width: $svgDim;
                            height: $svgDim;
                            min-width: $svgDim;
                            min-height: $svgDim;
                            max-width: $svgDim;
                            max-height: $svgDim;
                            margin-right: 0;
                            margin-bottom: 1rem;
                        }

                        > p{
                            text-align: center;
                            font-size: 2rem;
                        }
                    }
                }
            }
        }
    }
}