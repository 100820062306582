@import "../../shared.scss";

.home {
  width: 100%;

  > .section-1 {
    width: 100%;
    height: 100vh;
    min-height: $minFullScreenHeight;
    position: relative;
    filter: contrast(1.15);
    background-position: center;
    background-size: cover;
    &::before {
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      content: "";
      background: linear-gradient(#00000000, #00000030 60%, #000000e0);
      z-index: 1;
    }

    > video,
    > img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      z-index: 0;

      &.mobile {
        display: none;
      }
    }

    > .bottom-row {
      position: absolute;
      left: 0;
      bottom: 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      z-index: 2;

      > .last-match {
        width: 38rem;
        padding: 1rem 2rem 0.5rem 5rem;
        display: flex;
        flex-direction: column;
        background-color: #981b594c;

        > .title {
          width: auto;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          border-bottom: 1px solid #ffffff43;
          margin-bottom: 1rem;
          padding-bottom: 0.5rem;

          > p {
            font-family: $primaryFont;
            color: #ffffffa0;
            font-size: 1.2rem;
            margin: 0;
          }

          > p.title {
            font-family: $primaryFont;
            color: white;
            font-size: 1.2rem;
          }
        }

        > .main {
          display: flex;
          flex-direction: row;
          width: auto;
          margin-bottom: 1rem;

          > .section-1,
          > .section-2 {
            // width: 50%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-evenly;
          }

          > .section-1 {
            position: relative;
            justify-content: space-between;
            padding: 0 1rem;
            > img {
              height: 4rem;
              width: 4rem;
              object-fit: contain;
              object-position: center;
            }

            > p.score {
              font-family: $primaryFont;
              font-size: 2rem;
              color: white;
              margin: 0;
            }

            &::after {
              position: absolute;
              content: "";
              right: 0;
              width: 0;
              height: 75%;
              top: 12.5%;
              border-right: 1px solid $primaryColor;
            }
          }

          > .section-2 {
            width: auto;
            flex: 1;
            > .item {
              display: flex;
              flex-direction: row;
              align-items: center;
              opacity: 0.8;
              text-decoration: none;
              cursor: pointer;

              > img {
                height: 1.2rem;
                width: auto;
                margin-right: 1rem;
              }

              > p {
                font-family: $primaryFont;
                font-size: 1rem;
                color: white;
              }
            }
          }
        }
      }

      @keyframes sliding-background {
        0% {
          background-position: 100% 0;
        }

        80% {
          background-position: 0% 0;
        }

        100% {
          background-position: 0% 0;
        }
      }

      > .hashtag {
        right: 5rem;
        bottom: -1rem;
        background: linear-gradient(
            120deg,
            rgba($primaryColor, 0.7),
            rgba($primaryColor, 0.7) 8%,
            rgba($secondaryColor, 0.9) 18%,
            rgba($secondaryColor, 0.9) 48%,
            rgba($primaryColor, 0.7) 56%,
            rgba($secondaryColor, 0.9) 65%,
            rgba($secondaryColor, 0.9)
          ),
          radial-gradient(
            circle at left,
            rgba(gold, 1),
            rgba($primaryColor, 0.5) 14%,
            rgba($primaryColor, 0.5)
          );
        background-size: 600% 100%;
        animation: sliding-background 3s forwards;
        animation-timing-function: cubic-bezier(0.93, 0.38, 0.46, 0.98);
        background-repeat: no-repeat;
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-family: $primaryFont;
        font-size: 3rem;
        color: #ffffff50;
        position: absolute;
      }
    }
  }

  > .section-2 {
    background-color: #f7f7f7;

    > .title {
      width: 100%;
      height: 20rem;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      padding-bottom: 5rem;
      background-image: url(../../Assets/stripe-background.svg);
      background-repeat: repeat;
      // background-image: url(../../Assets/diagonal_lines4.png);
      // background-size: 100px auto;

      > .main {
        width: 100%;
        max-width: $innerMaxWidth;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        > h2 {
          color: $secondaryColor;
          font-family: $primaryFont;
          letter-spacing: 0.1rem;
          font-size: 3.5rem;
          margin: 0;
        }

        > .buttons {
          display: flex;
          flex-direction: row;
          height: 100%;
          align-items: center;

          > button {
            width: 4rem;
            height: 4rem;
            background-color: white;
            outline: none;
            border: none;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            box-shadow: 0 0.5rem 0.5rem #00000015;
            transition: background-color 0.2s ease;

            > img {
              height: 30%;
            }

            &:first-child {
              margin-right: 2rem;

              > img {
                transform: rotate(180deg);
              }
            }

            &:disabled {
              background-color: #ffffff95;
              cursor: default;
            }
          }
        }
      }
    }
    > .main {
      width: 100%;
      padding: 6rem 0 12rem;
      display: flex;
      flex-direction: column;
      align-items: center;

      > .carousel {
        width: 100%;
        max-width: $innerMaxWidth;
        display: flex;
        flex-direction: row;
        overflow-x: hidden;
      }
    }
  }

  .section-3 {
    width: 100%;
    background-color: $secondaryColor;
    padding: 12rem 0 15rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    > .title {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10em;
      max-width: $innerMaxWidth;
      padding: 0 2rem;

      > p.hashtag {
        font-family: $primaryFont;
        font-size: 4rem;
        color: $primaryColor;
        margin: 0;

        > span {
          color: white;
        }
      }

      > .buttons {
        display: flex;
        flex-direction: row;
        width: 50%;
        justify-content: space-evenly;

        > button {
          background-color: transparent;
          outline: none;
          border: none;
          cursor: pointer;
          font-family: $primaryFont;
          font-size: 2.3rem;
          color: #ffffff80;
          transition: color 0.2s ease;

          &.active {
            color: white;
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }

    > .main {
      $arrowButtonWidth: 4rem;
      width: 100%;
      position: relative;
      overflow: hidden;
      background-color: transparent;
      min-height: 40rem;

      > .images {
        width: 100%;
        position: absolute;
        height: 100%;
        top: 0;
        left: 0;
        pointer-events: none;
        z-index: 0;

        > button {
          display: none;
        }

        > div {
          position: absolute;
          height: 100%;
          width: 22rem; //remove to stop using GradientImage component

          &::after {
            width: 100%;
            height: 100%;
            content: "";
            background: linear-gradient(
              rgba($secondaryColor, 0),
              rgba($secondaryColor, 0) 50%,
              rgba($secondaryColor, 1)
            );
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
          }
          > img {
            height: 100%;
          }
        }
      }

      > .inner {
        width: 100%;
        max-width: $innerMaxWidth;
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        align-items: center;
        position: relative;
        z-index: 10;
        min-height: 40rem;

        > button {
          width: $arrowButtonWidth;
          height: $arrowButtonWidth;
          background-color: white;
          outline: none;
          border: none;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          box-shadow: 0 0.5rem 0.5rem #00000015;
          transition: background-color 0.2s ease;

          > img {
            height: 30%;
          }

          &:first-child {
            margin-right: 2rem;

            > img {
              transform: rotate(180deg);
            }
          }

          &:disabled {
            background-color: #ffffff95;
            cursor: default;
          }
        }

        > .mobile-content {
          display: none;
        }

        > .desktop-content {
          flex: 1;
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          margin-right: 5rem;

          > * {
            width: calc(60% - 15rem);
          }

          > .title {
            display: flex;
            flex-direction: row;
            margin-bottom: 1rem;
            align-items: center;
            justify-content: flex-start;
            height: 15rem;
            position: relative;
            overflow-x: visible;

            > p.number {
              font-family: $primaryFont;
              font-size: 15rem;
              margin: 0 2rem 0 0;
              color: $tertiaryColor;
              width: 10rem;
              text-align: start;
              line-height: 1;
              position: absolute;
              top: 0;
              right: 100%;
              display: none;
            }

            > p.name {
              font-family: $primaryFont;
              font-size: 6rem;
              text-align: start;
              color: white;
              margin: 0;
              font-weight: 400;
            }
          }

          > p.position {
            font-family: $primaryFont;
            font-size: 2.5rem;
            margin: 0 0 1rem;
            text-align: start;
            color: white;
            opacity: 0.5;
          }

          > .stats {
            display: flex;
            flex-direction: row;
            margin: 0;
            justify-content: flex-start;
            margin-bottom: 2rem;

            > .item {
              display: flex;
              flex-direction: column;
              margin-right: 3rem;

              &:last-child {
                margin-right: 0;
              }

              > p.value {
                font-family: $primaryFont;
                font-size: 2.5rem;
                color: white;
                text-align: start;
                margin: 0;
              }

              > p.name {
                font-family: $primaryFont;
                font-size: 1.5rem;
                color: white;
                text-align: start;
                opacity: 0.5;
                margin: 0;
              }
            }
          }

          > .socials {
            display: flex;
            flex-direction: row;
            margin: 0 0 4rem;
            justify-content: flex-start;

            > a {
              height: 1.5rem;
              cursor: pointer;
              background: none;
              outline: none;
              border: none;
              text-decoration: none;
              margin-right: 2rem;

              > img {
                height: 100%;
              }
            }
          }

          .buttons {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            > a {
              width: 15rem;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
              outline: none;
              border: none;
              cursor: pointer;
              height: 3rem;
              font-family: $primaryFont;
              font-size: 2rem;
              color: white;
              margin-bottom: 1.5rem;
              text-decoration: none;
              padding-top: 0.4rem;

              &.invisible {
                opacity: 0;
                pointer-events: none;
              }

              &.stats {
                background-color: $primaryColor;
              }

              &.get-jersey {
                background-color: $tertiaryColor;
              }
            }
          }
        }
      }
    }
  }
  > .section-4 {
    background-color: #f7f7f7;

    > .title {
      width: 100%;
      height: 20rem;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      padding-bottom: 5rem;
      background-image: url(../../Assets/stripe-background.svg);
      background-repeat: repeat;

      > .main {
        width: 100%;
        max-width: $innerMaxWidth;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        > h2 {
          color: $secondaryColor;
          font-family: $primaryFont;
          letter-spacing: 0.1rem;
          font-size: 4rem;
          margin: 0;
        }

        > a {
          outline: none;
          border: none;
          background-color: $secondaryColor;
          color: white;
          cursor: pointer;
          font-family: $primaryFont;
          font-size: 2rem;
          height: 4rem;
          padding: 0.5rem 3rem 0;
          display: flex;
          flex-direction: row;
          align-items: center;
          text-decoration: none;
        }
      }
    }

    > .main {
      padding: 5rem 0 10rem;
      overflow-x: hidden;

      > .table {
        width: 100%;
        max-width: $innerMaxWidth;
        display: flex;
        flex-direction: column;
        margin: 0 auto;

        .team {
          width: calc(100% / 6);
        }
        .position {
          width: calc(100% / 6);
        }
        .wins {
          width: calc(100% / 6);
        }
        .losses {
          width: calc(100% / 6);
        }
        .draws {
          width: calc(100% / 6);
        }
        .points {
          width: calc(100% / 6);
        }

        > .title,
        > .row {
          display: flex;
          flex-direction: row;
          width: 100%;
          border-bottom: 1px solid rgba($secondaryColor, 0.31);
          height: 7rem;

          > .item {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
          }
        }

        > .title {
          > .item {
            > img {
              height: 1rem;
              margin-right: 0.5rem;
            }

            > p {
              font-family: $primaryFont;
              font-size: 1.5rem;
              color: $secondaryColor;
              margin: 0.3rem 0 0;
            }
          }
        }

        > .row {
          position: relative;
          overflow-x: visible;

          &.qualified::before {
            position: absolute;
            height: 100%;
            width: 4rem;
            font-family: $primaryFont;
            font-size: 2rem;
            content: "Q";
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            color: $secondaryColor;
            left: 0;
            top: 0;
          }

          &.qualified.winner::before {
            display: none;
          }

          > svg {
            width: 4rem;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            height: 2rem;
            z-index: 1;

            * {
              fill: gold;
            }
          }

          > .item.team {
            > img {
              height: 60%;
              margin-left: 3rem;
            }
          }

          > .item {
            z-index: 1;
            position: relative;
            font-family: $primaryFont;
            font-size: 1.5rem;
            margin: 0.3rem 0 0;
            color: $secondaryColor;
          }

          &.highlighted {
            &.qualified::before {
              color: $primaryColor;
              z-index: 1;
            }

            > .item {
              color: white;
            }

            &::after {
              width: 100vw;
              height: 100%;
              background-color: $secondaryColor;
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              z-index: 0;
            }
          }
        }
      }

      > a {
        display: none;
      }
    }
  }

  > .section-5 {
    width: 100%;
    background-color: $secondaryColor;
    padding-bottom: 5rem;

    > .media {
      position: relative;
      width: 100%;
      height: auto;
      max-height: 50rem;
      display: flex;
      overflow: hidden;

      > img,
      > video {
        width: 100%;
        min-height: 0;
        max-height: 100%;
        object-fit: cover;
        object-position: center;
      }

      &::after {
        width: 100%;
        height: 100%;
        position: absolute;
        content: "";
        background: linear-gradient(
          rgba($secondaryColor, 0) 40%,
          rgba($secondaryColor, 1)
        );
        top: 0;
        left: 0;
        pointer-events: none;
      }
    }

    > .title {
      width: 100%;
      max-width: $innerMaxWidth;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 5rem 0 6.5rem;
      margin: 0 auto;

      > h2 {
        font-family: $primaryFont;
        font-size: 4rem;
        color: white;
        margin: 0 2rem 0 0;
        font-weight: normal;

        > span {
          color: $primaryColor;
        }
      }

      > .line {
        flex: 1;
        height: 0;
        border-top: 1px solid white;
        margin-right: 2rem;
      }

      > p.small {
        font-family: $primaryFont;
        font-size: 1.5rem;
        color: white;
        margin: 0 2rem 0 0;
      }

      > button,
      a {
        outline: none;
        border: none;
        background-color: $primaryColor;
        color: white;
        font-family: $primaryFont;
        font-size: 1.75rem;
        height: 2.5rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0.5rem 3rem 0;
        cursor: pointer;
        text-decoration: none;
      }
    }

    > .main {
      width: 100%;
      max-width: $innerMaxWidth;
      height: 20rem;
      margin: 0 auto 5rem;
      display: flex;
      flex-direction: row;
      overflow-x: hidden;

      > a.item {
        height: 100%;
        text-decoration: none;
        // Width and margin should be in sync with animations in HomePage.js
        width: 23rem;
        min-width: 23rem;
        margin-right: 0.5rem;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        position: relative;
        overflow-y: hidden;
        cursor: pointer;

        &::after {
          width: 100%;
          height: 110%;
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          background: linear-gradient(#00000000, #000000ff 90%, #000000ff);
          transition: transform 0.3s ease;
          transform: translateY(calc(100% / 11));
          z-index: 10;
        }

        &:hover::after {
          transform: translateY(0);
        }

        > img {
          position: absolute;
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }

        > .row {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          position: relative;
          z-index: 15;
          padding: 1.5rem 2rem;

          > p {
            font-family: $primaryFont;
            font-size: 1.8rem;
            margin: 0;
            color: white;
            text-align: start;
            transition: font-size 0.2s ease;
          }

          > img {
            height: 1rem;
            transition: height 0.2s ease, width 0.2s ease;
            margin-left: 2rem;
          }
        }

        &:hover {
          > .row {
            > p {
              font-size: 1.8rem;
            }

            > img {
              height: 1.3rem;
            }
          }
        }
      }
    }

    > .controls {
      width: 100%;
      height: 4rem;
      max-width: $innerMaxWidth;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 0 auto 5rem;

      > .line {
        height: 0;
        flex: 1;
        border-top: 1px solid #ffffff80;
      }

      > .buttons {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 100%;
        margin: 0 0 0 3rem;

        > button {
          width: 4rem;
          height: 4rem;
          background-color: white;
          outline: none;
          border: none;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          box-shadow: 0 0.5rem 0.5rem #00000015;
          transition: background-color 0.2s ease;

          > img {
            height: 30%;
          }

          &:first-child {
            margin-right: 2rem;

            > img {
              transform: rotate(180deg);
            }
          }

          &:active {
            background-color: #f3f3f3;
          }

          &:disabled {
            background-color: #ffffff95;
            cursor: default;
          }
        }
      }
    }
  }

  > .section-6 {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 15rem 0;

    > .title {
      display: none;
    }

    > .main {
      width: 100%;
      max-width: $innerMaxWidth;
      height: 28rem;
      display: flex;
      flex-direction: row;
      margin: 0 auto 5rem;

      .text {
        width: 22rem;
        min-width: 22rem;
        max-width: 22rem;
        background-color: white;
        height: 100%;
        margin-right: 0.5rem;
        display: flex;
        flex-direction: column;
        justify-content: center;

        > p.large {
          font-family: $primaryFont;
          font-size: 2.3rem;
          margin: 0 0 1.5rem;
          color: $secondaryColor;
          line-height: 1.2;
          text-align: start;
        }

        > p.small {
          font-family: $primaryFont;
          font-size: 1.7rem;
          margin: 0;
          color: $secondaryColor;
          text-align: start;
        }
      }

      > .content {
        overflow-x: hidden;
        display: flex;
        flex-direction: row;

        > a.item {
          width: 24rem;
          min-width: 24rem;
          max-width: 24rem;
          height: 100%;
          margin-right: 0.5rem;
          position: relative;
          background-color: #e6e6e4;
          display: flex;

          &:last-child {
            margin-right: 0;
          }

          > img {
            width: 100%;
            max-width: 100%;
            height: 100%;
            max-height: 100%;
            object-fit: contain;
            object-position: bottom center;
          }
        }
      }
    }

    > .controls {
      width: 100%;
      max-width: $innerMaxWidth;
      height: 4rem;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin: 0 auto;

      > .buttons {
        display: flex;
        flex-direction: row;
        height: 100%;
        align-items: center;

        > button {
          width: 4rem;
          height: 4rem;
          background-color: white;
          outline: none;
          border: none;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          box-shadow: 0.3rem 0.3rem 0.5rem #00000015;
          transition: background-color 0.4s ease;

          > img {
            height: 30%;
          }

          &:first-child {
            margin-right: 2rem;

            > img {
              transform: rotate(180deg);
            }
          }

          &:disabled {
            background-color: #f8f8f8;
            cursor: default;
          }
        }
      }

      > a {
        height: 3rem;
        font-family: $primaryFont;
        font-size: 2rem;
        color: white;
        background-color: $secondaryColor;
        padding: 0.4rem 5rem 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        outline: none;
        border: none;
        cursor: pointer;
        text-decoration: none;
      }
    }
  }

  > .section-7 {
    background-color: $secondaryColor;
    width: 100%;
    height: 58rem;
    position: relative;
    --textWidth: 80rem;

    @-webkit-keyframes ticker {
      0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        visibility: visible;
      }

      100% {
        -webkit-transform: translateX(calc(-1 * var(--textWidth)));
        transform: translateX(calc(-1 * var(--textWidth)));
      }
    }

    @keyframes ticker {
      0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        visibility: visible;
      }

      100% {
        -webkit-transform: translateX(calc(-1 * var(--textWidth)));
        transform: translateX(calc(-1 * var(--textWidth)));
      }
    }

    > .ticker {
      width: 100%;
      display: flex;
      flex-direction: row;
      overflow-x: hidden;
      position: absolute;
      bottom: 10rem;
      left: 0;
      z-index: 1;

      > p {
        font-family: $primaryFont;
        font-size: 12rem;
        color: white;
        opacity: 0.07;
        margin: 0;
        width: var(--textWidth);
        display: block;
        min-width: var(--textWidth);
        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
        -webkit-animation-timing-function: linear;
        animation-timing-function: linear;
        -webkit-animation-name: ticker;
        animation-name: ticker;
        -webkit-animation-duration: 8s;
        animation-duration: 8s;
      }
    }

    > .main {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;
      position: relative;
      z-index: 5;

      > .content {
        width: 45%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
        $contentWidth: 60%;

        > p.large {
          font-family: $primaryFont;
          font-size: 3.5rem;
          color: white;
          margin: 0 0 1rem;
          width: $contentWidth;
          text-align: start;
          line-height: 1.2;

          > span {
            color: $primaryColor;
          }
        }

        > p.small {
          font-family: $primaryFont;
          font-size: 2rem;
          color: white;
          margin: 0 0 2rem;
          width: $contentWidth;
          text-align: start;
        }

        > .links {
          width: $contentWidth;
          display: flex;
          flex-direction: row;

          > a {
            height: 3rem;
            background: none;
            cursor: pointer;
            outline: none;
            border: none;
            margin-right: 1.5rem;
            text-decoration: none;

            > img {
              height: 100%;
              width: auo;
            }
          }
        }
      }

      > .image {
        width: 55%;
        height: 100%;
        position: relative;
        overflow: hidden;

        > img {
          width: 100%;
          height: 100%;
          position: absolute;
          bottom: 0;
          right: 0;
          object-fit: contain;
          object-position: bottom right;
        }
      }

      > .mobile-only {
        display: none;
      }
    }
  }

  > .section-8 {
    width: 100%;
    height: auto;
    position: static;
    display: flex;
    flex-direction: column;
    $row1Height: 40rem;
    $row2Height: 35rem;

    > .row-1 {
      width: 100%;
      display: flex;
      flex-direction: row;
      height: $row1Height;
      max-height: $row1Height;

      > .text-item {
        width: calc(100% / 3);
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 3rem 5rem;
        justify-content: center;
        position: relative;

        > .extra {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          margin: auto 0 1rem;

          > p.date {
            font-family: $primaryFont;
            font-size: 1.5rem;
            color: $secondaryColor;
            margin: 0 2rem 0 0;
          }

          > p.tag {
            border-width: 1px;
            border-style: solid;
            height: 2rem;
            width: 8rem;
            border-radius: 0.3rem;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            font-family: $primaryFont;
            font-size: 1rem;
            letter-spacing: 0.1rem;
            padding-top: 0.3rem;

            &.pink {
              color: $primaryColor;
              border-color: $primaryColor;
            }
          }
        }

        > h3 {
          font-family: $primaryFont;
          font-size: 3.2rem;
          color: $secondaryColor;
          font-weight: normal;
          margin: 0 0 1.5rem;
          text-align: start;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 5;
        }

        > p {
          font-family: $secondaryFont;
          font-size: 1.4rem;
          color: $secondaryColor;
          font-weight: normal;
          margin: 0 0 auto;
          text-align: start;
          letter-spacing: 0.01rem;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
        }

        > .buttons {
          width: 100%;
          height: 4rem;
          margin: auto 0 0;
          display: flex;
          flex-direction: row;
          justify-content: flex-end;

          > a {
            text-decoration: none;
            width: 4rem;
            height: 4rem;
            background-color: white;
            outline: none;
            border: none;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            box-shadow: 0 0.5rem 0.5rem #00000015;
            transition: background-color 0.2s ease;

            > img {
              height: 30%;
              width: auto;
            }

            &.disabled {
              background-color: #ffffff95;
              cursor: default;
            }
          }
        }
      }

      > .large-item {
        width: auto;
        flex: 1;
        height: 100%;

        > img {
          width: 100%;
          height: 100%;
          max-width: 100%;
          max-height: 100%;
          object-position: center;
          object-fit: cover;
        }
      }
    }

    > .row-2 {
      width: 100%;
      display: flex;
      flex-direction: row;
      height: $row2Height;
      overflow-y: hidden;

      > a.item {
        width: calc(100% / 3);
        height: 100%;
        position: relative;
        overflow: hidden;
        text-decoration: none;

        &::after {
          width: 100%;
          height: 110%;
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          background: linear-gradient(#00000000, #00000000 40%, #000000ff);
          transition: transform 0.3s ease;
          transform: translateY(calc(100% / 11));
          z-index: 2;
        }

        &:hover::after {
          transform: translateY(0);
        }

        > img {
          position: absolute;
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
          z-index: 1;
          top: 0;
          left: 0;
          transition: transform 0.2s ease;
        }

        &:hover {
          img {
            transform: scale(1.1);
            transform-origin: center;
          }
        }

        > .content {
          position: absolute;
          width: 100%;
          bottom: 0;
          left: 0;
          display: flex;
          flex-direction: column;
          z-index: 3;
          padding: 0 5rem;

          > .extra {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            margin: auto 0 1rem;

            > p.date {
              font-family: $primaryFont;
              font-size: 1.5rem;
              color: white;
              margin: 0 2rem 0 0;
            }

            > p.tag {
              border-width: 1px;
              border-style: solid;
              height: 2rem;
              width: 8rem;
              border-radius: 0.3rem;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
              font-family: $primaryFont;
              font-size: 1rem;
              letter-spacing: 0.1rem;
              color: white;
              border-color: white;
              padding-top: 0.3rem;
            }
          }
          > p.large {
            font-family: $primaryFont;
            font-size: 2.5rem;
            color: white;
            font-weight: normal;
            margin: 0 0 1.5rem;
            text-align: start;
          }
        }
      }
    }
  }

  > iframe.taggbox {
    width: calc(100% - 30rem);
    max-width: $innerMaxWidth;
    height: 60rem;
    margin: 5rem auto;
  }

  > .section-9 {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 5rem 0;

    &.mobile {
      display: none;
    }

    &.desktop {
      > .row {
        width: 100%;
        max-width: $innerMaxWidth;
        margin: 0 auto 5rem;
        display: flex;
        flex-direction: column;
        $itemWidth: 10rem;

        > .texts,
        > .images {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
        }

        > .texts {
          margin: 0 0 0.5rem;

          > p {
            font-family: $primaryFont;
            font-size: 1.5rem;
            color: $secondaryColor;
            margin: 0 5rem 0 0;
            width: $itemWidth;
            text-align: center;

            &:last-child {
              margin-right: 0;
            }
          }
        }

        > .images {
          > img {
            margin: 0 5rem 0 0;
            width: $itemWidth;
            height: 6rem;
            object-fit: contain;
            object-position: center;

            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }
  }

  @include low-width {
    > .section-1 {
      min-height: 500px;

      > video,
      > img {
        &.desktop {
          display: none;
        }

        &.mobile {
          display: block;
        }
      }

      > .bottom-row {
        > .last-match {
          width: 100%;
          padding: 2rem;

          > .title {
            p,
            p.title {
              font-size: 2rem;
              margin-bottom: 1rem;
            }
          }

          > .main {
            height: 10rem;
            > .section-1 {
              width: 50%;
              img {
                height: 5rem;
                width: auto;
              }

              > p.score {
                font-size: 3rem;
              }
            }

            > .section-2 {
              > .item {
                flex-direction: column;
                > img {
                  height: 2rem;
                  margin: 0 auto 2rem;
                }

                > p {
                  font-size: 2rem;
                  margin: 0;
                }
              }
            }
          }
        }

        .hashtag {
          display: none;
        }
      }
    }

    > .section-2 {
      > .title {
        height: auto;
        padding: 8rem 0;
        > .main {
          justify-content: center;
          padding: 0;

          > h2 {
            font-size: 5rem;
            margin: 0;
          }

          > .buttons {
            display: none;
          }
        }
      }

      > .main {
        padding: 10rem 0;

        > .carousel {
          overflow-x: auto;
        }
      }
    }

    > .section-3 {
      padding: 7rem 0;

      > .title {
        margin-bottom: 8rem;
        flex-direction: column;

        > p.hashtag {
          font-size: 5rem;
          margin-bottom: 4rem;
        }

        > .buttons {
          width: 100%;
          justify-content: center;

          > button {
            font-size: 2.5rem;
            margin: 0 2rem;
          }
        }
      }

      > .main {
        $arrowButtonWidth: 4rem;

        > .images {
          position: relative;
          width: 100%;
          height: 40rem;
          pointer-events: auto;

          > button {
            display: block;
            position: absolute;
            width: $arrowButtonWidth;
            height: $arrowButtonWidth;
            background-color: white;
            outline: none;
            border: none;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            box-shadow: 0 0.5rem 0.5rem #00000015;
            transition: background-color 0.2s ease;
            top: 50%;
            transform: translateY(-50%);
            z-index: 10;

            > img {
              height: 30%;
            }

            &:first-child {
              left: 2rem;

              > img {
                transform: rotate(180deg);
              }
            }

            &:last-child {
              right: 2rem;
            }

            &:disabled {
              background-color: #ffffff95;
              cursor: default;
            }
          }

          > div {
            width: calc(100vw - #{2 * $arrowButtonWidth} - 4rem);
            height: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            pointer-events: none;
            transform-origin: right;

            > img {
              width: 100%;
              height: auto;
              object-fit: contain;
              object-position: center;
            }
          }
        }

        > .inner {
          margin-top: -8rem;

          > button {
            display: none;
          }

          > .desktop-content {
            display: none;
          }

          > .mobile-content {
            display: block;
            width: calc(100vw - #{2 * $arrowButtonWidth} - 4rem);
            margin: auto;

            > .title {
              width: 100%;
              display: flex;
              flex-direction: row;
              margin-bottom: 4rem;
              justify-content: center;

              > p.number {
                font-family: $primaryFont;
                font-size: 20rem;
                margin: 0 2rem 0 0;
                color: $tertiaryColor;
                text-align: start;
                line-height: 0.8;
              }

              > .info {
                display: flex;
                flex-direction: column;

                > p.name {
                  font-family: $primaryFont;
                  font-size: 7rem;
                  text-align: start;
                  color: white;
                  margin: 0 0 2rem;
                  font-weight: 400;
                }

                > p.position {
                  font-family: $primaryFont;
                  font-size: 2.5rem;
                  margin: 0 0 1rem;
                  text-align: start;
                  color: white;
                  opacity: 0.5;
                }
              }
            }
            > .stats {
              display: flex;
              flex-direction: row;
              margin-bottom: 4rem;
              justify-content: space-around;
              margin-bottom: 2rem;

              > .item {
                display: flex;
                flex-direction: column;
                margin-right: 3rem;

                &:last-child {
                  margin-right: 0;
                }

                > p.value {
                  font-family: $primaryFont;
                  font-size: 4rem;
                  color: white;
                  text-align: center;
                  margin: 0 0 1rem;
                }

                > p.name {
                  font-family: $primaryFont;
                  font-size: 1.8rem;
                  color: white;
                  text-align: center;
                  opacity: 0.5;
                  margin: 0;
                }
              }
            }

            > .socials {
              display: flex;
              flex-direction: row;
              margin: 0 0 4rem;
              justify-content: space-around;
              align-items: center;

              > a {
                height: 2.5rem;
                cursor: pointer;
                background: none;
                outline: none;
                border: none;
                text-decoration: none;
                margin-right: 2rem;

                > img {
                  height: 100%;
                }
              }

              > a.stats,
              > a.get-jersey {
                width: 5rem;
                height: 5rem;
                margin-right: 1rem;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                border-radius: 1rem;
                outline: none;
                border: none;
                padding: 0;

                > img {
                  width: 50%;
                  height: 50%;
                  object-position: center;
                  object-fit: contain;
                }
              }

              > a.stats {
                background-color: $tertiaryColor;
              }

              > a.get-jersey {
                background-color: $primaryColor;
              }
            }
          }
        }
      }
    }

    > .section-4 {
      > .title {
        height: auto;
        padding: 8rem 0;

        > .main {
          justify-content: center;

          > h2 {
            font-size: 5rem;
          }

          > a {
            display: none;
          }
        }
      }

      > .main {
        padding: 8rem 1.5rem 8rem 4rem;
        align-items: center;

        > .table {
          margin-bottom: 10rem;

          > .title,
          > .row {
            height: 10rem;
          }

          > .title {
            > .item {
              flex-direction: column;
              > img {
                height: 1.5rem;
              }

              > p {
                font-size: 1.7rem;
              }
            }
          }

          > .row {
            &.qualified::before {
              left: auto;
              right: 100%;
            }

            > svg {
              left: auto;
              right: 100%;
            }

            > .item {
              font-size: 1.8rem;
            }
          }
        }

        > a {
          outline: none;
          border: none;
          background-color: $secondaryColor;
          color: white;
          cursor: pointer;
          font-family: $primaryFont;
          font-size: 2.5rem;
          height: 5rem;
          width: fit-content;
          padding: 0 5rem;
          display: flex;
          flex-direction: row;
          align-items: center;
          margin: auto;
          text-decoration: none;
        }
      }
    }

    > .section-5 {
      padding: 10rem 0;

      > .media {
        display: none;
      }

      > .title {
        padding: 0 3rem;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 5rem;

        > h2 {
          text-align: start;
          max-width: 50%;
          margin-right: 0;
        }

        > .line {
          display: none;
        }

        > p.small {
          display: none;
        }

        > button,
        a {
          height: 5rem;
          font-size: 2.5rem;
          padding: 0.5rem 5rem 0;
          min-width: auto;
          text-decoration: none;
        }
      }

      > .main {
        height: 50rem;
        overflow-x: auto;
        margin: 0;

        > .item {
          width: 35rem;
          min-width: 35rem;
          margin-right: 1.5rem;

          &:first-child {
            margin-left: 3.5rem;
          }

          &:last-child {
            margin-right: 3.5rem;
          }

          > .row {
            padding: 3rem;
            > p {
              font-size: 3.5rem;
            }

            > img {
              height: 3rem;
            }
          }
        }
      }

      > .controls {
        display: none;
      }
    }

    > .section-6 {
      > .title {
        display: block;
        width: 100%;
        font-size: 5rem;
        font-family: $primaryFont;
        color: $secondaryColor;
        margin: 0 0 5rem;
        text-align: center;
      }

      > .main {
        height: auto;
        margin: 0 0 5rem;

        .text {
          display: none;
        }

        > .content {
          overflow-x: auto;

          > .item {
            width: 35rem;
            min-width: 35rem;
            height: 50rem;
            margin-right: 1.5rem;

            &:first-child {
              margin-left: 3.5rem;
            }

            &:last-child {
              margin-right: 3.5rem;
            }
          }
        }
      }

      > .controls {
        > .buttons {
          display: none;
        }

        > a {
          margin: auto;
          font-size: 3rem;
          height: 5rem;
        }
      }
    }

    > .section-7 {
      height: auto;

      > .ticker {
        top: 50%;
        bottom: auto;
        transform: translateY(-50%);
      }

      > .main {
        flex-direction: column;
        padding: 10rem 0;

        > .content {
          width: 100%;
          padding: 0 3rem;

          > p.large {
            width: 100%;
            text-align: center;
          }

          > p.small,
          > .links {
            display: none;
          }
        }

        > .image {
          width: 100%;
          height: 120vw;
          min-height: 120vw;
          margin-bottom: 5rem;

          > img {
            width: 100%;
            height: auto;
            right: -10%;
          }
        }

        > .mobile-only {
          display: flex;
          flex-direction: column;

          > p.small {
            font-family: $primaryFont;
            font-size: 3rem;
            color: white;
            margin: 0 0 2rem;
            width: 100%;
            text-align: center;
          }

          > .links {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;

            > a {
              height: 4rem;
              background: none;
              cursor: pointer;
              outline: none;
              border: none;
              margin-right: 2.5rem;
              text-decoration: none;

              > img {
                height: 100%;
                width: auo;
              }
            }
          }
        }
      }
    }

    > .section-8 {
      > .row-1 {
        flex-direction: column-reverse;
        height: auto;
        max-height: none;

        > .text-item {
          width: 100%;
          height: auto;
          min-height: 40rem;
          padding: 5rem;

          > .extra {
            > p.date {
              font-size: 2rem;
            }

            > p.tag {
              height: 3.5rem;
              width: 10rem;
              border-radius: 0.5rem;
              font-size: 2rem;
            }
          }

          > h3 {
            font-size: 4rem;
            margin: 0 0 3rem;
          }

          > p {
            font-size: 2.2rem;
            line-height: 1.2;
            margin-bottom: 5rem;
          }

          > .buttons {
            margin-top: auto;
            height: 5rem;

            > button {
              height: 5rem;
              width: 5rem;
            }
          }
        }

        > .large-item {
          width: 100%;
          height: 40rem;
        }
      }

      > .row-2 {
        flex-direction: column;
        height: auto;

        > .item {
          width: 100%;
          height: 120vw;
          max-height: 60rem;

          > .content {
            padding: 5rem;

            > .extra {
              > p.date {
                font-size: 2rem;
              }

              > p.tag {
                height: 3.5rem;
                font-size: 2rem;
                width: 12rem;
                border-radius: 0.5rem;
                letter-spacing: 0.2rem;
              }
            }

            > p.large {
              font-size: 4rem;
            }
          }
        }
      }
    }

    > iframe.taggbox {
      width: calc(100% - 10rem);
      max-width: $innerMaxWidth;
      height: 75rem;
      margin: 5rem auto;
    }

    > .section-9 {
      &.desktop {
        display: none;
      }

      &.mobile {
        display: flex;
        flex-direction: column;
        padding: 3rem 0;

        > .row {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-evenly;
          align-items: center;
          margin-bottom: 5rem;

          > .item {
            display: flex;
            flex-direction: column;
            height: auto;
            width: 8rem;
            margin: 0 2rem 2rem;
            justify-content: space-between;
            align-items: center;

            &:last-child {
              margin-right: 0;
            }

            &:first-child {
              margin-left: 0;
            }

            &.multiple {
              width: 100%;
              max-width: 100%;
              margin: 0 0 2rem;
            }

            > p {
              font-family: $primaryFont;
              font-size: 1.5rem;
              color: $secondaryColor;
              margin: 0 0 1rem;
              width: 100%;
              text-align: center;
            }

            > img {
              width: 100%;
              height: 8rem;
              object-fit: contain;
              object-position: center;
            }

            > .images {
              width: 100%;
              height: auto;
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              justify-content: space-between;
              align-items: center;

              > img {
                width: 100%;
                max-width: 8rem;
                height: 100%;
                object-fit: contain;
                object-position: center;
                margin: 0 auto;
              }
            }
          }
        }
      }
    }
  }
}
