@import '../../shared.scss';

.merchandise-page{
    width: 100%;

    > p.banner-text{
        background-color: $primaryColor;
        color: white;
        width: 100%;
        font-family: sans-serif;
        font-size: 1rem;
        padding: 1rem 0;
        position: relative;
        text-align: center;
        margin: var(--headerHeight) 0 0;
        transition: margin 0.2s ease;
        
        &.invisible{
            margin-top: calc(var(--headerHeight) - 4rem);
        }
        
        > svg{
            cursor: pointer;
            position: absolute;
            height: 1rem;
            width: auto;
            top: 1rem;
            right: 3rem;
        }
    }

    > .carousel{
        width: 100%;
        height: 40rem;
        position: relative;
        margin-bottom: 8rem;

        > .main{
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            overflow-x: hidden;

            > img{
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                // left: 0;
                object-fit: cover;
                object-position: center;
                transform-origin: center;

                &.shrink-left{
                    transform-origin: left;
                }

                &.shrink-right{
                    transform-origin: right;
                }
            }
        }

        > .controls{
            --buttonSize: 12px;
            position: absolute;
            height: auto;
            min-height: auto;
            padding: 0;
            bottom: 2rem;
            right: 5rem;
            z-index: 2;
            display: flex;
            flex-direction: row;

            > button{
                display: block;
                width: var(--buttonSize);
                height: var(--buttonSize);
                max-width: var(--buttonSize);
                max-height: var(--buttonSize);
                border-radius: calc(0.5 * var(--buttonSize));
                border: none;
                padding: 0;
                background-color: #B0B0B0;
                outline: none;
                margin-right: 1.5rem;
                cursor: pointer;

                &.active{
                    background-color: white;
                }

                &:last-child{
                    margin-right: 0;
                }
            }
        }
    }

    > .products{
        width: 100%;
        max-width: $innerMaxWidth;
        margin: 0 auto;

        > .filters{
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            margin-bottom: 2rem;
            
            > .item{
                display: flex;
                flex-direction: row;
                align-items: center;
                cursor: pointer;
                width: auto;

                > p{
                    font-family: $secondaryFontSemibold;
                    font-size: 1rem;
                    margin-right: 0.5rem;
                    white-space: nowrap;
                    color: black;
                }

                > img{
                    height: 0.5rem;
                    width: auto;
                }
            }
        }

        > .main{
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            flex-wrap: wrap;
            margin-bottom: 5rem;

            > a.item{
                width: calc((100% - 3rem)/3);
                height: 41rem;
                display: flex;
                flex-direction: column;
                margin-bottom: 1.5rem;
                margin-right: 1.5rem;
                text-decoration: none;
                cursor: pointer;

                &:nth-child(3n){
                    margin-right: 0;
                }

                > .imagebox{
                    width: 100%;
                    height: 27rem;
                    margin-bottom: 1rem;
                    background-color: #E6E6E4;
                    position: relative;
                    
                        > img{
                            position: absolute;
                            height: 90%;
                            width: 90%;
                            max-height: 90%;
                            max-width: 90%;
                            bottom: 0;
                            left: 50%;
                            transform: translateX(-50%);
                            object-fit: contain;
                            object-position: bottom;
                        }
                }

                > .info{
                    width: 100%;
                    height: auto;
                    display: flex;
                    flex-direction: column;

                    > p{
                        text-align: start;
                    }

                    > p.tag{
                        font-family: $secondaryFontSemibold;
                        font-size: 0.9rem;
                        color: $primaryColor;
                        margin: 0 0 0.8rem;
                    }

                    > p.name{
                        font-family: $secondaryFontSemibold;
                        font-size: 0.95rem;
                        color: black;
                        margin: 0 0 0.8rem;
                    }
                    > p.sub-text{
                        font-family: $secondaryFont;
                        font-size: 0.95rem;
                        color: #757575;
                        margin: 0 0 0.8rem;
                    }
                    > p.price{
                        font-family: $secondaryFontSemibold;
                        font-size: 0.95rem;
                        color: black;
                        margin: 0 0 0.8rem;
                    }
                }
            }
        }
    }

    @include low-width{
        > p.banner-text{
            font-size: 1.5rem;
            padding: 1.5rem 0;
        }

        > .carousel{
            height: 50rem;

            > .controls{
                --buttonSize: 8px;
            }
        }

        > .products{
            > .filters{
                justify-content: flex-start;
                padding: 0 3rem;
                
                > .item{
                    > p{
                        font-size: 2rem;
                    }
                    
                    > img{
                        height: 1rem;
                    }
                }
            }
            
            > .main{
                padding: 0 3rem;
                margin-bottom: 10rem;

                > a.item{
                    width: 100%;
                    height: auto;
                    margin-right: 0;
                    margin-bottom: 3rem;

                    > .imagebox{
                        height: calc(100vw - 6rem);
                        margin-bottom: 3rem;

                        > img{
                            width: 100%;
                            height: 100%;
                            max-width: 100%;
                            max-height: 100%;
                        }
                    }

                    > .info{
                        > p.tag{
                            font-size: 1.85rem;
                            margin-bottom: 1.5rem;
                        }

                        > p.name, > p.sub-text, > p.price{
                            font-size: 2rem;
                        }

                        > p.sub-text{
                            margin-bottom: 1.5rem;
                        }
                    }
                }
            }
        }


    }
}