$primaryFont: 'Gothic', sans-serif;
$secondaryFont: 'OpenSans-Regular', sans-serif;
$secondaryFontBold: 'OpenSans-Bold', sans-serif;
$secondaryFontLight: 'OpenSans-Light', sans-serif;
$secondaryFontSemibold: 'OpenSans-Semibold', sans-serif;
$tertiaryFont: 'BigNoodle', sans-serif;
$quaternaryFont: 'Brother1816', sans-serif;
$minFullScreenHeight: 768px;
$primaryColor: #EE4A9B;
$secondaryColor: #04358D;
$tertiaryColor: #6EACD5;
$innerMaxWidth: 80rem;
$headerHeight: 9rem;

$tinyScreen: 320px;
$mobileScreen: 500px; // value change affects FixtureAndResults file 
$tabletScreen: 768px;
$desktopScreen: 1024px;

@mixin low-width{
    @media (max-width: $mobileScreen) {
        @content;
    }
}

@mixin medium-width{
    @media (min-width: #{$mobileScreen + 1}) and (max-width: $tabletScreen){
        @content;
    }
}

@mixin medium-and-above {
    @media (min-width: #{$mobileScreen + 1}) {
        @content;
    }    
}

@mixin medium-and-below {
    @media (max-width: $tabletScreen) {
        @content;
    }
}

@mixin high-width{
    @media (min-width: #{$tabletScreen + 1}){
        @content;
    }
}

@mixin safari-only{
    @media not all and (min-resolution:.001dpcm){ 
        @supports (-webkit-appearance:none) {
            @content;
        }
    }
}