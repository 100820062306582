@import "../../../shared.scss";

.transparent-dropdown {
  width: 13rem;
  height: 2.5rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  border: 1px solid rgba(white, 0.5);
  border-radius: 0.3rem;
  .MuiInputBase-root {
    .MuiSelect-icon {
      left: 0 !important;
      top: 60% !important;
    }
  }
  > .MuiInputBase -root {
    width: 100%;
    height: 100%;
    > .makeStyles-icon-14 {
      left: 0 !important;
      top: 60% !important;
    }
  }
}

@include low-width {
  .transparent -dropdown {
    width: 20rem;
    height: 3.75rem;
    border-radius: 0.45rem;
  }
}
