@import '../../shared.scss';

.standings-page{
    width: 100%;
    height: auto;
    min-height: $minFullScreenHeight;
    padding-top: $headerHeight;

    > .section-1{
        width: 100%;
        height: 18rem;
        position: relative;
        padding: 2rem 10rem 0;
        display: flex;
        flex-direction: column;
        justify-content: center;

        &::after{
            width: 100%;
            height: 100%;
            position: absolute;
            background: $secondaryColor;
            opacity: 0.93;
            top: 0;
            left: 0;
            content: "";
            z-index: 0;
            pointer-events: none;
        }
        
        > img.background, > video.background{
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
        }

        > .title-and-controls{
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            position: relative;
            z-index: 1;


            > h1{
                font-family: $tertiaryFont;
                font-size: 7.5rem;
                margin: 0 5rem 0 0;
                width: auto;
                font-weight: normal;
                text-align: start;
                color: white;
            }

            .transparent-dropdown{
                margin-left: auto;
            }
            
        }
    }

    > .section-2-desktop{
        padding: 5rem 0 10rem;
        overflow-x: hidden;

        > h2{
            width: 100%;
            height: auto;
            padding: 3rem 0;
            text-align: center;
            font-size: 5rem;
            font-family: $tertiaryFont;
            color: $secondaryColor;
            font-weight: normal;
            letter-spacing: 0.05rem;
            margin: 0 0 3rem;
            background: url(../../Assets/stripe-background.svg);
        }

        > .table{
            width: 100%;
            max-width: $innerMaxWidth;
            margin: 0 auto 5rem;
            
            > .row{
                display: flex;
                flex-direction: row;
                height: 5rem;
                position: relative;

                &:not(.highlighted):nth-child(even){
                    &::before{
                        width: 100vw;
                        height: 100%;
                        background-color: #F6F8FD;
                        content: "";
                        position: absolute;
                        top: 0;
                        left: 0;
                        z-index: 0;
                    }
                }

                > * {
                    position: relative;
                    z-index: 1;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    font-family: $primaryFont;
                    font-size: 1rem;
                    color: $secondaryColor;

                    > p{
                        margin: 0.3rem 0 0;
                    }
                }

                > .position{
                    width: 10%;
                    flex-grow: 1;
                }
                
                > .club{
                    width: 10%;
                    flex-grow: 2;
                    justify-content: flex-start;

                    &.winner{
                        > svg{
                            width: 1.5rem;
                            height: auto;
                            margin-right: 1.5rem;
                            transform: translateX(-25%);

                            *{
                                fill: gold;
                            }
                        }

                        > .qualified-text{
                            display: none;
                        }
                    }

                    > .qualified-text{
                        width: 3rem;
                        height: 100%;
                        font-family: $primaryFont;
                        color: $primaryColor;
                        font-size: 2rem;
                        opacity: 0;
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        align-items: center;
                        cursor: default;

                        &.visible{
                            opacity: 1;
                        }
                    }

                    > img{
                        height: 50%;
                        width: 40px;
                        margin-right: 1.5rem;
                    }
                }

                > .matches{
                    width: 5%;
                    flex-grow: 1;
                }

                > .wins{
                    width: 5%;
                    flex-grow: 1;
                }

                > .losses{
                    width: 5%;
                    flex-grow: 1;
                }

                > .draws{
                    width: 5%;
                    flex-grow: 1;
                }

                > .difference{
                    width: 5%;
                    flex-grow: 1;
                }
                
                > .points{
                    width: 5%;
                    flex-grow: 1;
                }
                
                > .form{
                    width: 10%;
                    flex-grow: 2;

                    > .item{
                        --itemWidth: 13px;
                        width: var(--itemWidth);
                        height: var(--itemWidth);
                        min-width: var(--itemWidth);
                        min-height: var(--itemWidth);
                        border-radius: 50%;
                        background-color: $secondaryColor;
                        margin-right: 0.3rem;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: center;

                        &:last-child{
                            margin-right: 0;
                        }

                        &.loss{
                            background-color: #BA3C3C;
                        }

                        &.draw{
                            &::after{
                                width: 80%;
                                height: 1px;
                                background-color: white;
                                content: "";
                            }
                        }
                    }
                }


                &.highlighted{

                    &::before{
                        width: 100vw;
                        height: 100%;
                        background-color: $secondaryColor;
                        content: "";
                        position: absolute;
                        top: 0;
                        left: 0;
                        z-index: 0;
                    }

                    > *{
                        color: white;
                    }

                    > .club{
                        > .qualified-text{
                            color: white;
                        }
                    }

                    > .form{
                        > .item{
                            background-color: white;
                            &.loss{
                                background-color: #BA3C3C;
                            }
                            
                            &.draw{
                                &::after{
                                    width: 80%;
                                    height: 3px;
                                    background-color: $secondaryColor;
                                    content: "";
                                }
                            }
                        }
                    }
                }

                &.title{
                    > * {
                        color: rgba(#113370, 0.4);
                        justify-content: center;
                    }
                }
            }
        }
    }

    > .section-2-mobile{
        display: none;
    }

    @include low-width{

        > .section-1{
            height: auto;
            padding: 5rem;

            > .title-and-controls{
                flex-direction: column;
                align-items: flex-start;

                > h1{
                    margin-bottom: 3rem;
                }

                > .transparent-dropdown{
                    margin-left: 0;
                    height: 4rem;
                }
            }
        }

        > .section-2-desktop{
            display: none;
        }

        > .section-2-mobile{
            background-color: #F7F7F7;
            display: block;
            
            > h2{
                width: 100%;
                height: auto;
                padding: 3rem 0;
                text-align: center;
                font-size: 5rem;
                font-family: $tertiaryFont;
                color: $secondaryColor;
                font-weight: normal;
                letter-spacing: 0.05rem;
                margin: 0;
                background: url(../../Assets/stripe-background.svg);
            }
            
            > .table{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                min-height: 100vh;
                height: auto;
                padding: 5rem 2rem;
                flex-wrap: wrap;
            }
        }
    }
}