@import "../../../shared.scss";

.dialog-content {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: $minFullScreenHeight;
  display: flex;
  flex-direction: column;

  > button.close-button {
    width: 3rem;
    height: 3rem;
    position: absolute;
    top: 1rem;
    right: 3rem;
    border-radius: 1.5rem;
    background-color: black;
    outline: none;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    > img {
      width: 50%;
      height: 50%;
      object-fit: contain;
      object-position: center;
    }
  }

  > .main {
    width: 100%;
    height: 85%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;

    > button {
      outline: none;
      border: none;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: transparent;
      width: 10%;
      height: 100%;

      &:first-child {
        transform: rotate(-180deg);
      }

      > .button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 3rem;
        height: 3rem;
        border-radius: 1.5rem;
        background-color: black;

        > svg {
          width: 50%;
          height: 50%;

          * {
            fill: white;
            transition: opacity 0.2s ease;
          }
        }
      }

      &:disabled {
        cursor: default;

        > .button {
          > svg {
            * {
              opacity: 0.4;
            }
          }
        }
      }
    }

    > img {
      height: 90%;
      width: auto;
      max-width: 80%;
      flex: 1;
      object-fit: contain;
      object-position: center;
    }

    > iframe {
      flex: 1;
      width: auto;
      height: 90%;
    }

    > .mobile-controls {
      display: none;
    }
  }

  > .row {
    width: 100%;
    height: 15%;
    padding: 0.5rem 5rem 2rem;
    overflow: hidden;
    margin: 0 auto;
    white-space: nowrap;

    > img {
      display: inline-block;
      height: 100%;
      width: auto;
      border-radius: 0.5rem;
      margin-right: 1rem;
      filter: brightness(0.5);
      cursor: pointer;
      transition: filter 0.2s ease;

      &.selected {
        filter: brightness(1);
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  @include low-width {
    height: 100vh;
    min-height: 0;

    > button.close-button {
      width: 4rem;
      height: 4rem;
      top: 3rem;
      right: 3rem;
      border-radius: 2rem;
      z-index: 1;
    }

    > .main {
      flex-direction: column;
      align-items: none;
      justify-content: center;
      height: 75%;
      position: relative;
      z-index: 0;

      > button {
        display: none;
      }

      > img {
        width: 90%;
        flex: 0 0;
        height: auto;
        margin: auto auto;
      }

      > iframe {
        width: 90%;
        flex: 0 0;
        height: 70vw;
        min-height: 70vw;
        margin: auto auto;
      }

      > .mobile-controls {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        position: absolute;
        bottom: 5rem;

        > button {
          outline: none;
          border: none;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 5rem;
          height: 5rem;
          border-radius: 2.5rem;
          background-color: black;
          margin: 0 1.5rem;

          &:first-child {
            transform: rotate(-180deg);
          }

          > svg {
            width: 50%;
            height: 50%;

            * {
              fill: white;
              transition: opacity 0.2s ease;
            }
          }

          &:disabled {
            cursor: default;
            > svg {
              * {
                opacity: 0.4;
              }
            }
          }
        }
      }
    }
  }
}
