@import "../../shared.scss";

.news-page {
  width: 100%;
  height: auto;
  padding-top: var(--headerHeight);

  > .section-1 {
    width: 100%;
    padding: 2rem 10rem 0;
    background-color: $secondaryColor;

    > h1 {
      font-family: $primaryFont;
      font-size: 7.5rem;
      margin: 1rem 0 3.5rem;
      width: 100%;
      font-weight: normal;
      text-align: start;
      color: white;
    }

    > .tabs {
      width: 100%;
      position: relative;

      > .tab-row {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;

        > p.item {
          font-family: "Gothic";
          font-size: 1.5rem;
          color: white;
          opacity: 0.5;
          padding: 0 0 0.6rem;
          margin: 0 2rem 0 0;
          cursor: pointer;
          transition: opacity 0.5s ease;

          &.active {
            opacity: 1;
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }

      > .highlight-row {
        width: 100%;
        position: relative;
        height: 0.4rem;
        min-height: 0.4rem;

        > .highlighter {
          position: absolute;
          height: 100%;
          background-color: $primaryColor;
        }
      }
    }
  }

  > .section-2 {
    padding: 2.5rem 0;
    margin-bottom: 10rem;

    > .row {
      width: 100%;
      max-width: $innerMaxWidth;
      margin: 0 auto;
      height: 43rem;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;

      > .item {
        height: 100%;
        width: calc(25% - 1rem);
        display: flex;
        flex-direction: column;
        margin-right: 1rem;

        &:last-child {
          margin-right: 0;
        }

        &.wide {
          width: calc(50% - 1rem);

          > .imagebox {
            > .label {
              left: 10rem;
            }
          }

          > .textbox {
            padding: 2rem;

            > p {
              -webkit-line-clamp: 4;
            }
          }
        }

        > .imagebox {
          width: 100%;
          height: 23rem;
          min-height: 23rem;
          position: relative;

          > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
          }

          > .label {
            height: 3.5rem;
            width: auto;
            max-width: auto;
            padding: 0 2rem;
            display: flex;
            flex-direction: row;
            position: absolute;
            bottom: 0;
            left: 50%;
            background-color: $secondaryColor;
            align-items: center;
            z-index: 10;
            transform: translate3d(-50%, 25%, 0);

            > p.date {
              font-family: $secondaryFont;
              font-size: 0.9rem;
              color: white;
              margin: 0 1rem 0 0;
              min-width: auto;
              word-break: none;
              white-space: nowrap;
            }

            > p.tag {
              min-width: auto;
              font-family: $secondaryFont;
              font-size: 0.7rem;
              color: white;
              padding: 0.5rem 1rem;
              letter-spacing: 0.1rem;
              border: 1px solid rgba(white, 0.8);
              border-radius: 0.3rem;
              text-transform: uppercase;
            }
          }
        }

        > .textbox {
          width: 100%;
          height: auto;
          flex: 1;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 2rem 1rem;

          > img,
          > .image-filler {
            width: auto;
            height: 2rem;
            margin: 0.5rem 0 1.5rem;
            object-fit: contain;
            object-position: left center;
          }

          > h3 {
            font-family: $primaryFont;
            font-size: 2.5rem;
            margin: 0 0 1rem;
            font-weight: normal;
            color: $secondaryColor;
            text-align: start;
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
          }

          > p {
            font-family: $secondaryFont;
            font-size: 1rem;
            color: $secondaryColor;
            text-align: start;
            min-height: 0;
            flex-shrink: 1;
            margin: 0 0 1rem;
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            text-overflow: ellipsis;
          }

          > .links {
            width: 100%;
            height: 3rem;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            margin-top: auto;

            > p {
              font-family: $secondaryFont;
              font-size: 0.8rem;
              color: $primaryColor;
              margin: 0 2rem 0 0;
            }

            > a {
              width: 3rem;
              height: 3rem;
              background-color: white;
              outline: none;
              border: none;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              box-shadow: 0 0.5rem 0.5rem #00000015;
              transition: background-color 0.2s ease;

              > img {
                height: 30%;
              }
            }
          }
        }
      }
    }
  }

  @include low-width {
    > .section-1 {
      padding: 4rem 0 0;

      > h1 {
        font-family: $tertiaryFont;
        font-size: 8.5rem;
        margin-bottom: 6rem;
        padding: 0 4rem;
      }

      > .tabs {
        overflow-x: auto;

        > .tab-row {
          padding: 0 4rem;

          > p.item {
            font-size: 2.5rem;
            margin: 0 4rem 0 0;
          }
        }
      }
    }

    > .section-2 {
      padding: 5rem 0;

      > .row {
        height: auto;
        flex-direction: column;

        > .item {
          width: 100%;
          height: auto;
          min-height: auto;
          margin-right: 0;

          &.wide {
            width: 100%;

            > .imagebox {
              > .label {
                left: 15rem;
              }
            }

            > .textbox {
              padding: 3rem 5rem 8rem 3rem;
            }
          }

          > .imagebox {
            min-height: 35rem;
            height: auto;

            > img {
              height: auto;
              min-height: 35rem;
            }

            > .label {
              height: 6rem;
              padding: 0 3rem;
              left: 15rem;

              > p.date {
                font-size: 1.5rem;
              }

              > p.tag {
                font-size: 1.2rem;
                text-transform: uppercase;
              }
            }
          }

          > .textbox {
            padding: 3rem 5rem 5rem 3rem;

            > img,
            > .image-filler {
              height: 4rem;
              margin: 2rem 0 4rem;
            }

            > h3 {
              font-size: 4rem;
              margin: 0 0 4rem;
            }

            > p {
              font-size: 1.8rem;
              display: block;
            }

            > .links {
              justify-content: flex-end;
              height: auto;

              > p {
                display: none;
              }

              > a {
                width: 5rem;
                height: 5rem;
              }
            }
          }
        }
      }
    }
  }
}
