body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    --primaryFont: 'Gothic', sans-serif;
    --secondaryFont: 'OpenSans-Regular', sans-serif;
    --secondaryFontBold: 'OpenSans-Bold', sans-serif;
    --secondaryFontLight: 'OpenSans-Light', sans-serif;
    --secondaryFontSemibold: 'OpenSans-Semibold', sans-serif;
    --tertiaryFont: 'BigNoodle', sans-serif;
    --quaternaryFont: 'Brother1816', sans-serif;
    --minFullScreenHeight: 768px;
    --primaryColor: #EE4A9B;
    --secondaryColor: #04358D;
    --tertiaryColor: #6EACD5;
    --innerMaxWidth: 80rem;
    --headerHeight: 9rem;
}

body *{
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-tap-highlight-color: transparent;
}

@media(max-width: 768px){
    html{
        font-size: 7.7px;
    }
}
  
@media (min-width: 769px) and (max-width: 1280px){
    html{
        font-size: 1vw;
    }
}
  
@media (min-width: 1440px){
    html{
        font-size: 16px;
    }
}

@font-face {
    font-family: 'Gothic';
    src: url("./Fonts/Alternate\ Gothic\ No2\ D\ Regular.ttf") format('truetype');
}

@font-face {
    font-family: 'OpenSans-Regular';
    src: url("./Fonts/OpenSans-Regular.ttf") format('truetype');
}

@font-face {
    font-family: 'OpenSans-Bold';
    src: url("./Fonts/OpenSans-Bold.ttf") format('truetype');
}

@font-face {
    font-family: 'OpenSans-Semibold';
    src: url("./Fonts/OpenSans-SemiBold.ttf") format('truetype');
}

@font-face {
    font-family: 'OpenSans-Light';
    src: url("./Fonts/OpenSans-Light.ttf") format('truetype');
}

@font-face {
    font-family: 'BigNoodle';
    src: url("./Fonts/big_noodle_titling.ttf") format('truetype');
}

@font-face {
    font-family: 'Brother1816';
    src: url("./Fonts/Brother-1816-Bold-Italic.ttf") format('truetype');
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
