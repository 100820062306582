@import '../../shared.scss';

.page-transition{
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 50;
    top: 0;
    left: 0;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;

    .MuiCircularProgress-indeterminate, .MuiCircularProgress-circleIndeterminate{
        animation-duration: 2s;
        animation-timing-function: cubic-bezier(.58,.12,.48,.91);
    }
}