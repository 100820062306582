@import '../../shared.scss';

.standings-mobile-card{
    width: calc(50% - 1rem);
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3rem;
    background-color: white;
    border-radius: 2rem;
    margin-bottom: 5rem;

    &.highlighted{
        background-color: $primaryColor;

        p{
            color: white !important;
        }

        > .item {
            > .indicator{
                background-color: rgba(#EEEEEE, 0.2);

                > .value{
                    background-color: white;
                }
            }
        }
    }

    > img{
        width: 60%;
        height: auto;
        margin-bottom: 4rem;
        flex: 1;
        object-fit: contain;
        object-position: center;
    }

    > p.large{
        font-family: $primaryFont;
        font-size: 8rem;
        margin: 0 0 2rem;
        line-height: 1;
        color: black;
        position: relative;
        width: 100%;
        text-align: center;
        // display: block;

        > svg{
            position: absolute;
            left: 0;
            bottom: 3rem;
            height: 3rem;
            width: auto;

            *{
                fill: gold;
            }
        }

        &.qualified{
            &::before{
                position: absolute;
                font-family: $primaryFont;
                font-size: 5rem;
                left: 0%;
                bottom: 1.5rem;
                color: $primaryColor;
                content: "Q";
            }
        }

        &.winner{
            &::before{
                display: none;
            }
        }
    }

    > .item{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        margin: 3rem 0;

        > .title{
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-end;
            margin-bottom: 1rem;

            > p.label{
                font-family: $primaryFont;
                font-size: 2.5rem;
                color: black;
                margin: 0;
                text-transform: uppercase;
            }
            
            > p.value{
                font-family: $primaryFont;
                font-size: 2rem;
                color: black;
                margin: 0;
            }
        }

        > .indicator{
            height: 5px;
            width: 100%;
            border-radius: 4px;
            background-color: rgba(#2F2F2F, 0.2);
            overflow: hidden;

            > .value{
                height: 100%;
                background-color: $primaryColor;
            }
        }
    }
}