@import '../../shared.scss';

.panthers-pack{
    width: 100%;
    height: auto;
    min-height: $minFullScreenHeight;

    > .section-1{
        width: 100%;
        height: 150vh;
        min-height: $minFullScreenHeight * 1.5;
        margin-bottom: 10rem;

        > .text-keyhole{
            width: 100%;
            height: 100vh;
            position: sticky;
            top: 0;
            left: 0;
            overflow: hidden;
            background-color: black;

            > .svg-wrapper{
                width: 100%;
                height: 100%;
                position: relative;
                top: 0;
                left: 0;
                z-index: 1;
                transform-origin: center;
                opacity: 1;
                will-change: opacity;
                pointer-events: none;

                > svg{
                    width: 100%;
                    height: 100%;
                    
                    text{
                        font-size: 10rem;
                        letter-spacing: 0.4rem;
                        font-family: $tertiaryFont;
                        fill: black;
                        text-anchor: middle; /* align center */
                        dominant-baseline: middle; /* vertical alignment fix */
                    }
                    > rect{
                        // filter: url(#seethrough);
                        fill: white;
                    }
                }
            }

            
            > img, > video{
                width: 100%;
                height: 100%;
                max-width: 100%;
                max-height: 100%;
                object-fit: cover;
                object-position: center;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 0;
            }
        }
    }
    
    > .section-2{
        width: 100%;
        background: url(../../Assets/spotted-background.png);
        background-size: 100% auto;
        background-repeat: no-repeat repeat;
        padding: 5rem 0 10rem;
        
        > .main{
            width: 100%;
            max-width: $innerMaxWidth;
            margin: 0 auto;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            >img.mobile-image{
                display: none;
            }

            > img.desktop-image{
                width: calc(40% - 1rem);
                height: auto;
                max-height: 50rem;
                object-fit: cover;
                object-position: center;
                border-radius: 1.5rem;
            }
        }
    }

    > .section-3{
        width: 100%;
        padding-bottom: 10rem;

        > .title{
            width: 100%;
            padding: 6rem 0 0;
            background-color: $secondaryColor;
    
            > h1{
                font-family: $primaryFont;
                font-size: 5.5rem;
                margin: 1rem 0 5rem;
                width: 100%;
                font-weight: normal;
                text-align: center;
                color: white;
            }
    
            > .tabs{
                width: 100%;
                position: relative;
                
                > .tab-row{
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
    
                    > p.item{
                        font-family: 'Gothic';
                        font-size: 1.5rem;
                        color: white;
                        opacity: 0.5;
                        padding: 0 0 0.6rem;
                        margin: 0 2rem 0 0;
                        cursor: pointer;
                        transition: opacity 0.5s ease;
        
                        &.active{
                            opacity: 1;
                        }
    
                        &:last-child{
                            margin-right: 0;
                        }
                    }
                }
    
    
                > .highlight-row{
                    width: 100%;
                    position: relative;
                    height: 0.4rem;
                    min-height: 0.4rem;
        
                    > .highlighter{
                        position: absolute;
                        height: 100%;
                        background-color: $primaryColor;
                    }
                }
            }
    
        }

        > .main{
            background: url(../../Assets/spotted-background.png);
            background-size: 100% auto;
            background-repeat: no-repeat repeat;
            padding: 8rem 0;

            > .tab{
                width: 100%;
                max-width: $innerMaxWidth;
                margin: 0 auto;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                flex-wrap: wrap;

                > .item{
                    width: calc(100%/3 - 2rem);
                    height: 40rem;
                    background-color: white;
                    padding: 1rem 1rem 2rem;
                    box-shadow: 0 0.2rem 0.7rem #00000020;
                    margin-right: 3rem;
                    margin-bottom: 2rem;
                    display: flex;
                    flex-direction: column;
                    text-decoration: none;
                    border: none;
                    outline: none;
                    cursor: pointer;

                    &:nth-child(3n){
                        margin-right: 0;
                    }

                    > img{
                        width: 100%;
                        height: 50%;
                        object-fit: cover;
                        object-position: center 20%; //TODO: MAKE IT CENTER CENTER LATER
                        margin-bottom: 3rem;
                    }

                    > p.title{
                        font-family: $primaryFont;
                        font-size: 2.2rem;
                        color: $secondaryColor;
                        text-transform: uppercase;
                        text-align: start;
                        margin: 0 1rem 2rem;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }

                    > p.text{
                        font-family: $secondaryFont;
                        font-size: 1rem;
                        color: $secondaryColor;
                        text-align: start;
                        margin: 0 1rem auto;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 3;
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }

                    > .buttons{
                        width: calc(100% - 2rem);
                        height: 5rem;
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        margin: auto 1rem 0;
                        margin-top: auto;
                        align-items: flex-end;
                        justify-self: flex-end;

                        > a, > button{
                            padding: 1rem 3rem 0.8rem;
                            background-color: $secondaryColor;
                            border-radius: 0.5rem;
                            outline: none;
                            border: none;
                            font-family: $primaryFont;
                            font-size: 1.2rem;
                            color: white;
                            cursor: pointer;
                            text-decoration: none;
                        }

                        > svg{
                            margin-left: auto;
                            height: 2rem;
                            width: 2rem;
                            *{
                                fill: $secondaryColor;
                            }
                        }
                    }
                }
            }
        }
    }

    @include low-width{
        > .section-1{
            > .text-keyhole{
                > .svg-wrapper{
                    > svg{
                        text{
                            font-size: 6rem;
                        }
                    }
                }
            }
        }

        > .section-2{
            padding: 1rem 2rem 10rem;

            > .main{
                flex-direction: column;

                >img.desktop-image{
                    display: none;
                }

                > img.mobile-image{
                    display: block;
                    width: 100%;
                    height: 30rem;
                    object-fit: cover;
                }
            }
        }

        > .section-3{
            > .title{
                >.tabs{
                    > .tab-row{
                        justify-content: space-evenly;
                        > p.item{
                            font-size: 2rem;
                        }
                    }
                }
            }

            > .main{
                > .tab{
                    padding: 0 2rem;

                    > .item{
                        width: 100%;
                        height: 70rem;
                        margin-right: 0;
                        display: flex;
                        flex-direction: column;
                        padding-bottom: 4rem;
                        margin-bottom: 3rem;

                        > img{
                            height: 60%;
                        }

                        > p.title{
                            font-size: 3rem;
                        }

                        > p.text{
                            font-size: 1.5rem;
                        }

                        > .buttons{
                            > a{
                                display: inline-block;
                                font-size: 2rem;
                                width: 70%;
                                padding: 2rem 0 1.5rem;
                            }

                            > svg{
                                height: 3.5rem;
                                width: auto;
                            }
                        }
                    }
                }
            }
        }
    }
}