@import "../../shared.scss";

.quizbox {
  height: auto;
  width: calc(60% - 1rem);
  background-color: $secondaryColor;
  border-radius: 1.5rem;
  display: flex;
  flex-direction: column;
  padding: 2rem 4rem 3rem;
  align-items: stretch;
  position: relative;
  overflow: hidden;

  > canvas {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 0;
  }

  > .row-1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5rem;

    > h2 {
      font-family: $tertiaryFont;
      font-size: 3.5rem;
      text-decoration: underline;
      font-weight: normal;
      color: white;
      margin: 0;
    }

    > .indicator {
      width: 8rem;
      height: 8rem;
      border-radius: 50%;
      position: relative;

      > .text {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        justify-content: center;

        > p.label {
          font-family: $tertiaryFont;
          font-size: 1rem;
          color: white;
          opacity: 0.45;
          text-align: center;
          margin: 0 0 0.5rem;
          position: relative;
          z-index: 1;
        }

        > p.value {
          font-family: $tertiaryFont;
          font-size: 2rem;
          color: white;
          text-align: center;
          margin: 0;
          position: relative;
          z-index: 1;
        }
      }

      > svg {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
        overflow: visible;
      }
    }
  }

  > p {
    font-size: 1.2rem;
    font-family: $secondaryFont;
    text-align: start;
    color: white;
    margin: 0 0 5rem;

    &.winner-question {
      font-size: 1.5rem;
      max-width: 80%;
    }
  }

  > .options {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 3rem;
    position: relative;
    z-index: 1;

    > .item {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding: 1rem 1.5rem;
      cursor: pointer;
      border-radius: 0.5rem;
      border: 1px solid rgba(#8292c3, 0.5);
      margin-bottom: 2rem;
      transition: border 0.2s ease;

      > .check {
        width: 1.5rem;
        height: 1.5rem;
        min-width: 1.5rem;
        min-height: 1.5rem;
        border-radius: 50%;
        border: 1px solid rgba(#8292c3, 0.5);
        margin-right: 1.5rem;
        transition: border 0.2s ease;
      }

      > p {
        font-family: $secondaryFont;
        font-size: 1rem;
        color: white;
        margin: 0;
        transition: color 0.2s ease;
      }

      &.selected {
        border-color: $primaryColor;

        > .check {
          border-color: $primaryColor;
        }

        > p {
          color: $primaryColor;
        }
      }
    }
  }

  > button {
    padding: 1.1rem 3rem 0.8rem;
    font-family: $primaryFont;
    font-size: 1.3rem;
    color: $secondaryColor;
    background-color: white;
    border-radius: 0.5rem;
    align-self: flex-end;
    outline: none;
    border: none;
    cursor: pointer;
    position: relative;
    z-index: 1;
    transform: scale(1);
    transform-origin: center;
    transition: transform 0.5s ease;

    &:disabled {
      cursor: default;
      opacity: 0.5;
    }

    &.invisible {
      transform: scale(0);
    }

    > .progress {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $secondaryColor;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  > .winner {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    > .correct-answer {
      width: 80%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      border-bottom: 1px solid #ffffff30;

      > p.title {
        font-family: $primaryFont;
        font-size: 1.5rem;
        color: white;
        opacity: 0.7;
        margin: 0 0 1rem;
      }

      > p.text {
        font-family: $secondaryFont;
        font-size: 2rem;
        color: $primaryColor;
        margin: 0 0 2rem;
      }
    }

    > .user {
      width: 80%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-top: 3rem;

      > p.title {
        font-family: $primaryFont;
        font-size: 1.5rem;
        color: white;
        opacity: 0.7;
        margin: 0 0 1rem;
      }

      > p.name {
        font-family: $secondaryFont;
        font-size: 2.5rem;
        color: white;
        margin: 0 0 1rem;
      }

      > .row {
        display: flex;
        flex-direction: row;
        align-items: center;

        > p.label {
          font-family: $primaryFont;
          font-size: 1.5rem;
          color: white;
          opacity: 0.7;
          margin: 0.5rem 2rem 0 0;
          text-transform: uppercase;
        }

        > p.value {
          font-family: $secondaryFont;
          font-size: 1.5em;
          color: white;
          margin: 0 2rem 0 0;
        }
      }
    }
  }

  @include low-width {
    width: 100%;
    margin-bottom: 3rem;
    padding: 2rem 2rem 3rem;

    > .row-1 {
      > .indicator {
        width: 11rem;
        height: 11rem;

        > .text {
          > p.label {
            font-size: 1.5rem;
          }

          > p.value {
            font-size: 2.5rem;
          }
        }
      }
    }

    > p {
      width: calc(100% - 2rem);
      margin: 0 auto 5rem;
      font-size: 1.8rem;
    }

    > .options {
      width: calc(100% - 2rem);
      margin: 0 auto 3rem;

      > .item {
        > .check {
          width: 2rem;
          height: 2rem;
          min-width: 2rem;
          min-height: 2rem;
        }

        > p {
          font-size: 1.5rem;
        }
      }
    }

    > button {
      padding: 1.5rem 4rem 1rem;
      font-size: 2.3rem;
      margin: 1rem 1rem 1rem auto;
    }
  }
}
