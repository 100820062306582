@import "../../shared.scss";

.matches-page {
  width: 100%;
  height: auto;
  min-height: $minFullScreenHeight;
  padding-top: $headerHeight;

  > .section-1 {
    width: 100%;
    height: 22rem;
    display: flex;
    flex-direction: column;
    background-color: $secondaryColor;

    > .row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      max-width: $innerMaxWidth;
      margin: auto;

      > h1 {
        font-family: $tertiaryFont;
        font-size: 7rem;
        margin: 0;
        font-weight: normal;
        text-align: start;
        color: white;
        line-height: 0.8;
      }
    }

    > .tabs {
      width: 100%;
      max-width: $innerMaxWidth;
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin: 0 auto;

      > .item {
        height: 3rem;
        padding: 0.5rem;
        font-family: $primaryFont;
        font-size: 1.5rem;
        color: white;
        position: relative;

        &::after {
          width: 100%;
          height: 0;
          position: absolute;
          bottom: 0;
          left: 0;
          border-top: 0.4rem solid $primaryColor;
          content: "";
        }
      }
    }
  }

  .section-2 {
    padding: 10rem 0;
    background: url(../../Assets/spotted-background.png);

    > .item {
      width: 100%;
      max-width: $innerMaxWidth + 10rem;
      height: auto;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin: 0 auto 5rem;

      > .time-and-place {
        width: 25rem;
        display: flex;
        flex-direction: column;
        padding-left: 8rem;
        justify-content: center;
        margin-right: 5rem;

        > .item {
          display: flex;
          flex-direction: row;
          width: 100%;
          height: auto;
          margin-bottom: 2rem;
          align-items: center;

          &:first-child {
            > p {
              color: $primaryColor;
            }
          }

          &:last-child {
            margin-bottom: 0;
          }

          > svg {
            width: 2.5rem;
            min-width: 2.5rem;
            height: auto;
            max-height: 2.5rem;
            margin-right: 2rem;
          }

          > p {
            font-family: $primaryFont;
            font-size: 1.5rem;
            line-height: 0.9;
            margin: 0;
            color: $secondaryColor;
            text-align: start;
          }
        }

        > a {
          height: 3rem;
          position: relative;
          margin-bottom: 1rem;
          width: 12rem;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          border-radius: 0.4rem;
          outline: none;
          border: none;
          cursor: pointer;
          text-decoration: none;
          overflow: hidden;

          &:last-child {
            margin-bottom: 0;
          }

          &::before,
          &:after {
            content: "";
            background: #fff;
            height: 50%;
            width: 0;
            position: absolute;
            transition: 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
          }

          &:before {
            top: 0;
            left: 0;
            right: auto;
          }
          &:after {
            bottom: 0;
            right: 0;
            left: auto;
          }

          > svg {
            height: 60%;
            width: auto;
            margin-right: 1rem;
            position: relative;
            z-index: 1;
          }

          > svg,
          > svg * {
            transition: fill 0.2s ease;
          }

          > p {
            font-family: $primaryFont;
            font-size: 1rem;
            color: white;
            margin: 0.3rem 0 0 0;
            line-height: 0.9;
            position: relative;
            z-index: 1;
            transition: color 0.2s ease;
          }

          &.blue {
            background-color: $secondaryColor;
            border: 1px solid $secondaryColor;

            &:hover {
              > svg,
              > svg * {
                fill: $secondaryColor;
              }

              > p {
                color: $secondaryColor;
              }
            }
          }

          &.pink {
            background-color: $primaryColor;
            border: 1px solid $primaryColor;

            &:hover {
              > svg,
              > svg * {
                fill: $primaryColor;
              }

              > p {
                color: $primaryColor;
              }
            }
          }

          &:hover {
            &:before {
              width: 100%;
              right: 0;
              left: auto;
            }
            &:after {
              width: 100%;
              left: 0;
              right: auto;
            }
          }
        }
      }

      > .card {
        width: auto;
        height: auto;
        display: flex;
        flex-direction: row;
        flex: 1;
        max-width: 60rem;
        box-shadow: 0 0.2rem 0.5rem 0.3rem #00000010;
        text-decoration: none;
        outline: none;
        border: none;

        > .main {
          width: auto;
          background-color: white;
          padding: 3rem 8rem 3em 4rem;
          display: flex;
          flex: 1;
          flex-direction: row;
          justify-content: space-between;
          position: relative;
          overflow-y: visible;

          > .type {
            position: absolute;
            background-color: $primaryColor;
            padding: 0.5rem 0;
            width: 20rem;
            border-radius: 1.25rem;
            display: flex;
            justify-content: center;
            align-items: center;
            top: 0;
            left: 50%;
            transform: translate(-50%, -50%);

            > p {
              font-family: $tertiaryFont;
              font-size: 1.5rem;
              color: white;
              margin: 0;
            }
          }

          > .scores {
            width: 30rem;
            height: 100%;
            display: flex;
            flex-direction: row;

            > .item {
              width: 50%;
              height: 100%;
              display: flex;
              flex-direction: column;
              align-items: center;

              &:first-child {
                border-right: 1px solid #00000020;
                align-items: center;
              }

              > img {
                width: 6rem;
                height: 6rem;
                margin-bottom: 2rem;
                object-fit: contain;
                object-position: center;

                &.small {
                  width: 4rem;
                  height: 4rem;
                }
              }

              > p.score {
                font-family: $primaryFont;
                font-size: 4.5rem;
                color: black;
                margin: 0 0 0.5rem;
                line-height: 0.8;
              }

              > p.name {
                font-family: $tertiaryFont;
                font-size: 1.2rem;
                color: #9e9e9e;
                margin: 0;
              }
            }
          }

          > .time-and-place {
            display: none;
          }

          > .links {
            width: calc(100% / 3);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-end;

            > a {
              height: 3rem;
              position: relative;
              margin-bottom: 1rem;
              width: 12rem;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
              border-radius: 0.4rem;
              outline: none;
              border: none;
              cursor: pointer;
              text-decoration: none;
              overflow: hidden;

              &:last-child {
                margin-bottom: 0;
              }

              &::before,
              &:after {
                content: "";
                background: #fff;
                height: 50%;
                width: 0;
                position: absolute;
                transition: 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
              }

              &:before {
                top: 0;
                left: 0;
                right: auto;
              }
              &:after {
                bottom: 0;
                right: 0;
                left: auto;
              }

              > svg {
                height: 60%;
                width: auto;
                margin-right: 1rem;
                position: relative;
                z-index: 1;
              }

              > svg,
              > svg * {
                transition: fill 0.2s ease;
              }

              > p {
                font-family: $primaryFont;
                font-size: 1rem;
                color: white;
                margin: 0.3rem 0 0 0;
                line-height: 0.9;
                position: relative;
                z-index: 1;
                transition: color 0.2s ease;
              }

              &.blue {
                background-color: $secondaryColor;
                border: 1px solid $secondaryColor;

                &:hover {
                  > svg,
                  > svg * {
                    fill: $secondaryColor;
                  }

                  > p {
                    color: $secondaryColor;
                  }
                }
              }

              &.pink {
                background-color: $primaryColor;
                border: 1px solid $primaryColor;

                &:hover {
                  > svg,
                  > svg * {
                    fill: $primaryColor;
                  }

                  > p {
                    color: $primaryColor;
                  }
                }
              }

              &:hover {
                &:before {
                  width: 100%;
                  right: 0;
                  left: auto;
                }
                &:after {
                  width: 100%;
                  left: 0;
                  right: auto;
                }
              }
            }
          }
        }

        > .title {
          width: 5rem;
          background-image: url(../../Assets/stripe-background.svg);
          background-repeat: repeat;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;

          > h2 {
            font-family: $primaryFont;
            font-size: 2.5rem;
            color: $secondaryColor;
            margin: 0.5rem 0 0 0;
            white-space: nowrap;
            transform: rotate(90deg);
            font-weight: normal;
          }
        }
      }
    }
  }

  @include low-width {
    > .section-1 {
      > .row {
        padding: 0 5rem;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        > h1 {
          margin-bottom: 3rem;
        }

        > .transparent-dropdown {
          margin-left: 0;
          height: 4rem;
          min-height: 4rem;
        }
      }

      > .tabs {
        display: none;
      }
    }

    > .section-2 {
      padding: 5rem 3rem;
      width: 100%;

      > .item {
        > .time-and-place {
          display: none;
        }

        > .card {
          width: 100%;
          flex-direction: column-reverse;

          > .title {
            width: 100%;
            height: 10rem;

            > h2 {
              transform: none;
              font-size: 5rem;
            }
          }

          > .main {
            flex-direction: column;
            padding: 8rem 0;

            > .type {
              border-radius: 1.5rem;

              > p {
                font-size: 2rem;
              }
            }

            > .scores {
              width: 100%;
              margin-bottom: 5rem;

              > .item {
                > img {
                  width: 8rem;
                  height: 8rem;

                  &.small {
                    width: 8rem;
                    height: 8rem;
                  }
                }
              }
            }

            > .time-and-place {
              width: 100%;
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              margin-bottom: 5rem;

              > .item {
                display: flex;
                flex-direction: column;
                width: 100%;
                height: auto;
                align-items: center;

                &:first-child {
                  > p {
                    color: $primaryColor;
                  }
                }

                > svg {
                  width: 4rem;
                  min-width: 4rem;
                  height: auto;
                  margin-bottom: 3rem;
                }

                > p {
                  font-family: $primaryFont;
                  font-size: 2rem;
                  line-height: 0.9;
                  margin: 0;
                  color: $secondaryColor;
                  text-align: center;
                }
              }
            }

            > .links {
              width: 100%;
              align-items: center;

              > a {
                height: 5rem;
                width: 20rem;
                border-radius: 0.8rem;
                margin-bottom: 2rem;

                > p {
                  font-size: 2rem;
                }
              }
            }
          }
        }
      }
    }
  }
}
