@import "../../../shared.scss";

.match-page {
  width: 100%;
  height: auto;
  min-height: $minFullScreenHeight;
  padding-top: $headerHeight;
  background: url(../../../Assets/spotted-background.png);
  background-size: 100% auto;
  background-repeat: no-repeat repeat;

  > .section-1 {
    $svgHeight: 1.5rem;
    width: 100%;
    max-width: $innerMaxWidth;
    margin: 12.5rem auto 8rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    position: relative;

    > .venue {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      > svg {
        height: $svgHeight;
        width: auto;
        margin-bottom: 0.8rem;
      }

      > p {
        font-family: $primaryFont;
        font-size: 1.5rem;
        color: black;
        line-height: 0.9;
        text-align: start;
        margin: 0;

        &.colored {
          margin: 0 0 0.5rem;
          color: $secondaryColor;
        }
      }
    }

    > .scores {
      display: flex;
      flex-direction: row;
      position: absolute;
      top: -4rem;
      left: 50%;
      transform: translateX(-50%);

      > .team {
        width: 10rem;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;

        > img {
          width: 6rem;
          height: 6rem;
          object-fit: contain;
          object-position: center;
          margin-bottom: 1rem;
        }

        > p {
          font-family: $tertiaryFont;
          font-size: 1.3rem;
          color: black;
          line-height: 1;
          margin: 0;
          letter-spacing: 0.08rem;
        }

        > p.score {
          display: none;
        }
      }

      > p.score {
        font-family: $primaryFont;
        font-size: 4.5rem;
        line-height: 0.9;
        margin: 0 2rem;
        color: black;
        align-self: center;
        min-width: 5rem;
      }
    }

    > .time {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      > svg {
        height: $svgHeight;
        width: auto;
        margin-bottom: 0.8rem;
      }

      > p {
        font-family: $primaryFont;
        font-size: 1.5rem;
        color: black;
        line-height: 0.9;
        text-align: end;
        margin: 0;

        &.colored {
          margin: 0 0 0.5rem;
          color: $primaryColor;
        }
      }
    }
  }

  > .mobile-time-and-place {
    display: none;
  }

  > .section-2 {
    width: 100%;

    > .stats {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 0 5rem;
      width: 100%;
    }

    > .top-performers {
      --width: 70rem;
      width: var(--width);
      padding-top: 4rem;
      background-color: $secondaryColor;
      border-radius: 1rem;
      overflow: hidden;
      margin: 0 auto 10rem;
      display: flex;
      flex-direction: column;

      > h4 {
        font-family: $primaryFont;
        font-size: 2rem;
        color: white;
        font-weight: normal;
        padding-bottom: 1rem;
        margin: 0 auto 3rem;
        position: relative;
        width: auto;

        &::after {
          width: calc(100% + 2rem);
          height: 0px;
          border-top: 1px solid white;
          left: -1rem;
          bottom: 0;
          position: absolute;
          content: "";
        }
      }

      > .main {
        display: flex;
        flex-direction: row;
        width: 100%;
        position: relative;

        > .divider {
          height: 100%;
          width: 0;
          position: absolute;
          border-right: 1px solid rgba(white, 0.2);
          left: 50%;
        }

        > .item {
          width: 50%;
          display: flex;
          flex-direction: row-reverse;
          align-items: flex-end;
          padding-top: 3rem;
          position: relative;
          right: -5%;

          &:first-child {
            flex-direction: row;
            left: -5%;
          }

          > .imagebox {
            width: 40%;
            height: auto;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            position: relative;

            &:after {
              position: absolute;
              width: 100%;
              height: 100%;
              content: "";
              top: 0;
              left: 0;
              background: linear-gradient(
                rgba($secondaryColor, 0) 0%,
                rgba($secondaryColor, 0.2) 40%,
                rgba($secondaryColor, 1) 90%
              );
            }

            > img {
              width: 100%;
              object-position: center;
              object-fit: contain;
              position: relative;
            }
          }

          > .info {
            width: 60%;
            display: flex;
            flex-direction: column;

            > .info-item {
              display: flex;
              flex-direction: column;
              align-items: center;
              margin-bottom: 2rem;

              > p.label {
                font-family: $primaryFont;
                font-size: 1rem;
                color: white;
                opacity: 0.45;
                margin: 0 0 0.5rem;
              }

              > p.value {
                font-family: $tertiaryFont;
                font-size: 2.5rem;
                color: white;
                margin: 0;
              }

              > .circular-item {
                width: 5rem;
                height: 5rem;
                border-radius: 50%;
                position: relative;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;

                > p {
                  font-family: $tertiaryFont;
                  font-size: 2rem;
                  color: white;
                  text-align: center;
                  margin: 0;
                  position: relative;
                  z-index: 1;
                }

                > svg {
                  position: absolute;
                  left: 0;
                  top: 0;
                  width: 100%;
                  height: 100%;
                  z-index: 0;
                  overflow: visible;
                }
              }
            }
          }
        }
      }
    }

    > .watch-out {
      display: none !important;
      --width: 70rem;
      width: var(--width);
      padding: 4rem;
      background-color: $secondaryColor;
      border-radius: 1rem;
      overflow: hidden;
      margin: 0 auto 10rem;
      display: flex;
      flex-direction: column;

      > h4 {
        font-family: $primaryFont;
        font-size: 2rem;
        color: white;
        font-weight: normal;
        padding-bottom: 1rem;
        margin: 0 auto 3rem;
        position: relative;
        width: auto;

        &::after {
          width: calc(100% + 2rem);
          height: 0px;
          border-top: 1px solid white;
          left: -1rem;
          bottom: 0;
          position: absolute;
          content: "";
        }
      }

      > .main {
        display: flex;
        flex-direction: row;
        width: 100%;
        position: relative;
        justify-content: space-evenly;

        > .divider {
          height: 100%;
          width: 0;
          position: absolute;
          border-right: 1px solid rgba(white, 0.2);
          left: 50%;
        }

        > .item {
          width: 50%;
          display: flex;
          flex-direction: row-reverse;
          align-items: flex-end;
          padding-top: 3rem;
          position: relative;

          &:first-child {
            flex-direction: row;
          }

          > .imagebox {
            width: 40%;
            height: auto;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            position: relative;

            &:after {
              position: absolute;
              width: 100%;
              height: 100%;
              content: "";
              top: 0;
              left: 0;
              background: linear-gradient(
                rgba($secondaryColor, 0) 0%,
                rgba($secondaryColor, 0.2) 40%,
                rgba($secondaryColor, 1) 90%
              );
            }

            > img {
              width: 100%;
              object-position: center;
              object-fit: contain;
              position: relative;
            }
          }

          > .info {
            width: 60%;
            display: flex;
            flex-direction: column;

            > .info-item {
              display: flex;
              flex-direction: column;
              align-items: center;
              margin-bottom: 2rem;

              > p.label {
                font-family: $primaryFont;
                font-size: 1rem;
                color: white;
                opacity: 0.45;
                margin: 0 0 0.5rem;
              }

              > p.value {
                font-family: $tertiaryFont;
                font-size: 2.5rem;
                color: white;
                margin: 0;
              }

              > .circular-item {
                width: 5rem;
                height: 5rem;
                border-radius: 50%;
                position: relative;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;

                > p {
                  font-family: $tertiaryFont;
                  font-size: 2rem;
                  color: white;
                  text-align: center;
                  margin: 0;
                  position: relative;
                  z-index: 1;
                }

                > svg {
                  position: absolute;
                  left: 0;
                  top: 0;
                  width: 100%;
                  height: 100%;
                  z-index: 0;
                  overflow: visible;
                }
              }
            }
          }
        }
      }

      > .allrounder-mobile {
        display: none;
      }

      > .allrounder-desktop {
        width: 80%;
        display: flex;
        flex-direction: column;
        padding-top: 3rem;
        position: relative;
        align-items: center;
        margin: 2rem auto;

        // &::before{
        //     width: calc(100% - 4rem);
        //     height: 0;
        //     position: absolute;
        //     left: 2rem;
        //     top: 0;
        //     border-top: 1px solid rgba(white, 0.2);
        //     content: "";
        // }

        > .title {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-bottom: 2rem;

          > p.label {
            font-family: $primaryFont;
            font-size: 1rem;
            color: white;
            opacity: 0.45;
            margin: 0 0 0.5rem;
          }

          > p.value {
            font-family: $tertiaryFont;
            font-size: 2.5rem;
            color: white;
            margin: 0;
          }
        }

        > .row {
          display: flex;
          flex-direction: row;
          justify-content: space-evenly;

          > .info {
            display: flex;
            flex-direction: column;

            > .info-item {
              display: flex;
              flex-direction: column;
              align-items: center;
              margin-bottom: 2rem;

              &.large {
                > p.label {
                  font-size: 1.5rem;
                  margin: 0 0 1.5rem;
                }

                > p.value {
                  font-family: $tertiaryFont;
                  font-size: 2.5rem;
                  color: white;
                  margin: 0;
                }

                > .circular-item {
                  width: 8rem;
                  height: 8rem;

                  > p {
                    font-size: 3rem;
                  }
                }
              }

              > p.label {
                font-family: $primaryFont;
                font-size: 1rem;
                color: white;
                opacity: 0.45;
                margin: 0 0 0.5rem;
              }

              > p.value {
                font-family: $tertiaryFont;
                font-size: 2.5rem;
                color: white;
                margin: 0;
              }

              > .circular-item {
                width: 5rem;
                height: 5rem;
                border-radius: 50%;
                position: relative;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;

                > p {
                  font-family: $tertiaryFont;
                  font-size: 2rem;
                  color: white;
                  text-align: center;
                  margin: 0;
                  position: relative;
                  z-index: 1;
                }

                > svg {
                  position: absolute;
                  left: 0;
                  top: 0;
                  width: 100%;
                  height: 100%;
                  z-index: 0;
                  overflow: visible;
                }
              }
            }
          }

          > .imagebox {
            width: 33%;
            height: auto;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            position: relative;

            &:after {
              position: absolute;
              width: 100%;
              height: 100%;
              content: "";
              top: 0;
              left: 0;
              background: linear-gradient(
                rgba($secondaryColor, 0) 0%,
                rgba($secondaryColor, 0.2) 40%,
                rgba($secondaryColor, 1) 90%
              );
            }

            > img {
              width: 100%;
              object-position: center;
              object-fit: contain;
              position: relative;
            }
          }
        }
      }
    }
  }

  @include low-width {
    > .section-1 {
      margin-top: 5rem;

      > .venue,
      > .time {
        display: none;
      }

      > .scores {
        position: static;
        width: 100%;
        justify-content: center;
        transform: none;

        > .team {
          width: 40%;

          &:first-child {
            border-right: 1px solid rgba(#707070, 0.3);
          }

          > img {
            width: 8rem;
            height: 8rem;
            margin-bottom: 2rem;
          }

          > p {
            font-size: 1.5rem;
            color: #9e9e9e;
          }

          > p.score {
            display: block;
            font-family: $primaryFont;
            font-size: 4.5rem;
            line-height: 0.9;
            margin: 0 0 2rem;
            color: black;
            align-self: center;
          }
        }

        > p.score {
          display: none;
        }
      }
    }

    > .mobile-time-and-place {
      $svgHeight: 2.5rem;
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      margin-bottom: 8rem;

      > .venue {
        display: flex;
        flex-direction: column;
        align-items: center;

        > svg {
          height: $svgHeight;
          width: auto;
          margin-bottom: 3rem;
        }

        > p {
          font-family: $primaryFont;
          font-size: 2rem;
          color: black;
          line-height: 0.9;
          text-align: center;
          margin: 0;

          &.colored {
            margin: 0 0 2rem;
            color: $secondaryColor;
          }
        }
      }

      > .time {
        display: flex;
        flex-direction: column;
        align-items: center;

        > svg {
          height: $svgHeight;
          width: auto;
          margin-bottom: 3rem;
        }

        > p {
          font-family: $primaryFont;
          font-size: 2rem;
          color: black;
          line-height: 0.9;
          text-align: center;
          margin: 0;

          &.colored {
            margin: 0 0 2rem;
            color: $primaryColor;
          }
        }
      }
    }

    > .section-2 {
      > .top-performers {
        width: 90%;
        padding: 6rem 0;
        border-radius: 2rem;

        > h4 {
          font-size: 3rem;
          margin-bottom: 5rem;
        }

        > .main {
          flex-direction: column;

          > .divider {
            display: none;
          }

          > .item {
            width: 100%;
            align-items: center;
            position: static;

            &:first-child {
              margin-bottom: 5rem;

              > .imagebox {
                > img {
                  object-position: right;
                }
              }
            }

            > .imagebox {
              height: 40rem;

              > img {
                height: 100%;
                width: auto;
                object-fit: cover;
                object-position: left;
              }
            }

            > .info {
              > .info-item {
                > p.label {
                  font-size: 2rem;
                  margin-bottom: 2rem;
                }

                > p.value {
                  font-size: 4rem;
                  margin-bottom: 2rem;
                }

                > .circular-item {
                  width: 8rem;
                  height: 8rem;

                  > p {
                    font-size: 3rem;
                  }
                }
              }
            }
          }
        }
      }

      > .watch-out {
        width: 90%;
        padding: 6rem 0;
        border-radius: 2rem;

        > h4 {
          font-size: 3rem;
          margin-bottom: 5rem;
        }

        > .main {
          flex-direction: column;

          > .divider {
            display: none;
          }

          > .item {
            width: 100%;
            flex-direction: column;
            align-items: center;
            padding-bottom: 4rem;
            margin-bottom: 5rem;

            &::after {
              content: "";
              position: absolute;
              width: 70%;
              height: 0;
              bottom: 0;
              left: 50%;
              transform: translateX(-50%);
              border-bottom: 1px solid rgba(white, 0.3);
            }

            &:first-child {
              flex-direction: column;
            }

            > .imagebox {
              width: 60%;
            }

            > .info {
              width: 100%;
              flex-direction: row;
              align-items: center;
              justify-content: center;
              flex-wrap: wrap;

              > .info-item {
                width: auto;
                margin: 0 2rem 2rem;

                &:first-child {
                  width: 100%;
                }

                > p.label {
                  font-size: 1.8rem;
                  margin-bottom: 2rem;
                }

                > p.value {
                  font-size: 4rem;
                  margin-bottom: 2rem;
                }

                > .circular-item {
                  width: 8rem;
                  height: 8rem;

                  > p {
                    font-size: 4rem;
                  }
                }
              }
            }
          }
        }

        > .allrounder-desktop {
          display: none;
        }

        > .allrounder-mobile {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding-top: 3rem;
          position: relative;

          > .imagebox {
            width: 60%;
            height: auto;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            position: relative;

            &:after {
              position: absolute;
              width: 100%;
              height: 100%;
              content: "";
              top: 0;
              left: 0;
              background: linear-gradient(
                rgba($secondaryColor, 0) 0%,
                rgba($secondaryColor, 0.2) 40%,
                rgba($secondaryColor, 1) 90%
              );
            }

            > img {
              width: 100%;
              object-position: center;
              object-fit: contain;
              position: relative;
            }
          }

          > .title {
            margin-bottom: 4rem;
            > p.label {
              font-family: $primaryFont;
              color: white;
              opacity: 0.45;
              font-size: 1.8rem;
              margin: 0 0 2rem;
            }

            > p.value {
              font-family: $tertiaryFont;
              color: white;
              font-size: 4rem;
              margin: 0 0 2rem;
            }
          }

          > .row {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 30rem;

            > .info {
              display: flex;
              flex-direction: column;

              > .info-item {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-bottom: 2rem;

                > p.label {
                  font-family: $primaryFont;
                  color: white;
                  opacity: 0.45;
                  font-size: 2rem;
                  margin: 0 0 2rem;
                }

                > p.value {
                  font-family: $tertiaryFont;
                  color: white;
                  font-size: 4rem;
                  margin-bottom: 0 0 2rem;
                }

                > .circular-item {
                  width: 8rem;
                  height: 8rem;
                  border-radius: 50%;
                  position: relative;
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  justify-content: center;

                  > p {
                    font-family: $tertiaryFont;
                    font-size: 3rem;
                    color: white;
                    text-align: center;
                    margin: 0;
                    position: relative;
                    z-index: 1;
                  }

                  > svg {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 0;
                    overflow: visible;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
