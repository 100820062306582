@import "../../shared.scss";

.sponsor-row {
  width: 100%;
  // margin: 0 auto 5rem;
  margin: 0 auto 3rem;
  display: flex;
  flex-direction: column;
  overflow: clip visible;

  &:first-child {
    margin-top: 4rem;
  }

  > p.title {
    width: 100%;
    font-family: $primaryFont;
    font-size: 2.5rem;
    color: $secondaryColor;
    margin: 0 0 1.5rem 0;
    text-align: center;
  }

  > .main {
    width: 100%;
    display: flex;
    flex-direction: row;
    overflow: clip visible;
    // height: 10rem;

    > .blocker {
      display: block;
      position: relative;
      height: 100%;
      min-height: 100%;
      width: auto;
      min-width: 0;
      flex: 1;
      background-color: white;
      z-index: 3;
    }

    > button {
      width: 10rem;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: white;
      border: none;
      outline: none;
      cursor: pointer;
      position: relative;
      z-index: 1;

      &:nth-child(2) {
        > img {
          transform: rotate(90deg);
        }
      }

      &:nth-child(4) {
        > img {
          transform: rotate(-90deg);
        }
      }

      > img {
        width: 3rem;
        height: auto;
        object-fit: contain;
        object-position: center;
      }
    }

    > .scroller {
      width: 80%;
      max-width: $innerMaxWidth * 0.8;
      position: relative;
      // height: 8rem;
      height: 100%;
      margin: auto auto 0;
      overflow: clip visible;
      z-index: 0;

      > .group {
        // position: absolute;
        width: 100%;
        max-width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        top: 0;
        left: 0;
        overflow: visible;

        > .item {
          width: 25%;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          position: relative;
          overflow: visible;

          > .subtitle {
            width: 100%;
            // height: 1.5rem;
            margin: 0 0 0.5rem;
            background-color: transparent;
            position: relative;
            z-index: 1;

            &.large {
              position: absolute;
              left: 0;
              bottom: calc(100% - 2rem);
              height: auto;
              z-index: 3;

              > p {
                font-size: 2.5rem;
              }
            }

            > p {
              width: 100%;
              font-family: $primaryFont;
              font-size: 1.5rem;
              color: $secondaryColor;
              margin: 0;
              text-align: center;
              height: auto;
            }
          }

          > a {
            width: 100%;
            height: calc(100% - 2rem);
            margin: auto 0 0;

            > img {
              width: auto;
              max-width: 80%;
              // height: 100%;
              margin: auto 0 0;
              object-fit: contain;
              object-position: center;
              position: relative;
              z-index: 0;
            }
          }
        }
      }
    }
  }
}