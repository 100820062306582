@import "../../../shared.scss";

.player-page {
  width: 100%;
  height: auto;
  min-height: $minFullScreenHeight;
  padding-top: $headerHeight;

  > svg {
    position: absolute;
    z-index: 2;
    width: auto;
    height: auto;
    opacity: 0;
    transform: scale(0);
    transition: transform 0.8s ease-in 0.5s, opacity 0.3s ease 0.5s;
  }

  > .section-1-mobile {
    display: none;
  }

  > .section-1-desktop {
    width: 100%;
    height: 47.5rem;
    background-color: $secondaryColor;
    padding: 11.5rem 0 8rem;
    display: flex;
    flex-direction: column;

    > h1 {
      font-family: $tertiaryFont;
      font-size: 7rem;
      margin: 0;
      color: white;
      font-weight: normal;
      line-height: 0.8;
      letter-spacing: 0.5rem;
      white-space: nowrap;

    }

    > .row {
      margin: auto auto 0;
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
      max-width: $innerMaxWidth;
      position: relative;

      > .group {
        width: auto;
        display: flex;

        &:first-child {
          flex-direction: row;

          > .socials,
          .jersey {
            width: auto;
            height: auto;
            display: flex;
            flex-direction: column;

            > p {
              width: 100%;
              font-family: $primaryFont;
              font-size: 1.5rem;
              color: white;
              margin: 0 0 1rem;
              text-align: center;
            }

            > .links {
              width: auto;
              display: flex;
              flex-direction: row;
              justify-content: center;

              > a {
                width: 3rem;
                height: 3rem;
                margin-right: 2rem;
                border-radius: 0.8rem;
                background-color: white;
                text-decoration: none;
                outline: none;
                border: none;
                cursor: pointer;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;

                &:last-child {
                  margin-right: 0;
                }

                > svg {
                  height: 50%;
                  width: auto;
                  max-width: 50%;
                  max-height: 50%;

                  * {
                    fill: $primaryColor;
                  }
                }
              }
            }
          }

          > .socials {
            margin-right: 4rem;
          }
        }

        &:nth-child(2) {
          position: absolute;
          left: 50%;
          bottom: 0;
          transform: translateX(-50%);
          width: 18rem;

          > .imagebox {
            position: absolute;
            width: 18rem;
            height: 32rem;
            min-height: auto;
            bottom: 2rem;
            left: 0;
            z-index: 0;
            display: flex;

            // &::after {
            //     width: 100%;
            //     height: 100%;
            //     content: "";
            //     background: linear-gradient(rgba($secondaryColor, 0), rgba($secondaryColor, 0.19) 50%, rgba($secondaryColor, 1) 98%);
            //     position: absolute;
            //     top: 0;
            //     left: 0;
            //     z-index: 1;
            // }
          }

          > p.number {
            font-family: $tertiaryFont;
            font-size: 5.8rem;
            color: $primaryColor;
            margin: 0;
            line-height: 0.8;
            text-align: center;
            margin: 0 auto;
            position: relative;
            z-index: 1;
          }
        }

        &:last-child {
          flex-direction: row;
          justify-content: center;
          align-items: center;

          > svg {
            height: 10rem;
            width: auto;

            @keyframes blink {
              0% {
                fill-opacity: 1;
                r: 5;
                stroke-opacity: 1;
              }
              40% {
                stroke-opacity: 1;
                fill-opacity: 0;
              }
              80% {
                stroke-opacity: 0;
                fill-opacity: 0;
                r: 10;
              }
              81% {
                stroke-opacity: 0;
                fill-opacity: 0;
                r: 2;
              }
              100% {
                fill-opacity: 1;
                stroke-opacity: 1;
                r: 5;
              }
            }

            > circle {
              display: none;
            }

            &.left-raider {
              > circle {
                &#left-raider {
                  display: block;
                  animation: blink 1.5s linear infinite;
                }
              }
            }
            &.left-raider {
              > circle {
                &#left-raider {
                  display: block;
                  animation: blink 1.5s linear infinite;
                }
              }
            }
            &.left-raider {
              > circle {
                &#left-raider {
                  display: block;
                  animation: blink 1.5s linear infinite;
                }
              }
            }
            &.right-raider {
              > circle {
                &#right-raider {
                  display: block;
                  animation: blink 1.5s linear infinite;
                }
              }
            }
            &.left-cover {
              > circle {
                &#left-cover {
                  display: block;
                  animation: blink 1.5s linear infinite;
                }
              }
            }
            &.right-cover {
              > circle {
                &#right-cover {
                  display: block;
                  animation: blink 1.5s linear infinite;
                }
              }
            }
            &.left-corner {
              > circle {
                &#left-corner {
                  display: block;
                  animation: blink 1.5s linear infinite;
                }
              }
            }
            &.right-corner {
              > circle {
                &#right-corner {
                  display: block;
                  animation: blink 1.5s linear infinite;
                }
              }
            }
            &.all-rounder {
              > circle {
                &#all-rounder {
                  display: block;
                  animation: blink 1.5s linear infinite;
                }
              }
            }
          }

          > p.position {
            font-family: $primaryFont;
            font-size: 3rem;
            color: white;
            transform: rotate(-90deg);
            transform-origin: center;
            margin: 0;
          }
        }
      }
    }
  }

  > .section-2 {
    width: 100%;
    height: auto;
    padding: 7rem 0 3rem;
    background-image: url(../../../Assets/spotted-background.png);
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    > .row {
      width: 100%;
      height: auto;
      max-width: $innerMaxWidth;
      margin: 0 auto 7rem;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;

      > .item {
        width: auto;
        height: auto;
        display: flex;
        flex-direction: column;

        > p.title {
          font-family: $primaryFont;
          font-size: 2rem;
          color: $secondaryColor;
          margin: 0 0 1rem;
          text-align: center;
        }

        > p.value {
          font-family: $secondaryFont;
          font-size: 1rem;
          color: $secondaryColor;
          margin: 0;
          text-align: center;
        }
      }
    }

    > .main {
      width: 60%;
      height: auto;
      margin: 0 auto;
      border-top: 1px solid rgba(#707070, 0.4);
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-top: 3rem;

      > img {
        width: 12rem;
        height: auto;
        margin-right: 8rem;
      }

      > .content {
        width: auto;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;

        > p.title {
          font-family: $primaryFont;
          font-size: 2.2rem;
          color: $secondaryColor;
          margin: 0 0 2.5rem;
          text-align: start;

          > span {
            color: $primaryColor;
          }
        }

        > p.text {
          font-family: $secondaryFont;
          font-size: 1.2rem;
          color: $secondaryColor;
          margin: 0 0 2rem;
          text-align: start;

          > span {
            color: $primaryColor;
          }
        }
      }
    }
  }

  > .section-3 {
    width: 100%;

    > .title {
      width: 100%;
      height: 17.5rem;
      background-image: url(../../../Assets/stripe-background.svg);
      background-repeat: repeat;
      display: flex;
      flex-direction: row;
      align-items: center;

      > h2 {
        width: 100%;
        margin: 0;
        line-height: 0.8;
        font-family: $primaryFont;
        font-size: 4.8rem;
        color: $secondaryColor;
        text-align: center;
        font-weight: normal;
        letter-spacing: 0.1rem;
      }
    }

    > .main {
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      background-image: url(../../../Assets//spotted-background.png);
      background-size: 100% auto;
      background-repeat: repeat-y;
      padding: 8rem 0;

      > .item {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 5.5rem;
        position: relative;

        > p.title {
          position: absolute;
          font-family: $primaryFont;
          font-size: 5.5rem;
          color: $primaryColor;
          margin: 0;
          right: 100%;
          transform: rotate(-90deg) translate(50%, -80%);
          transform-origin: center right;
        }
      }
    }
  }

  > .section-4 {
    width: 100%;
    margin-bottom: 10rem;

    > .title {
      width: 100%;
      height: 17.5rem;
      background-image: url(../../../Assets/stripe-background.svg);
      background-repeat: repeat;
      display: flex;
      flex-direction: row;
      align-items: center;

      > h2 {
        width: 100%;
        margin: 0;
        line-height: 0.8;
        font-family: $primaryFont;
        font-size: 4.8rem;
        color: $secondaryColor;
        text-align: center;
        font-weight: normal;
        letter-spacing: 0.1rem;
      }
    }

    > .main {
      width: 100%;
      height: 45rem;
      background-color: $secondaryColor;

      > .inner {
        width: 100%;
        max-width: $innerMaxWidth + 8rem;
        height: 100%;
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 2rem;

        > button {
          width: 4rem;
          height: 4rem;
          background-color: white;
          outline: none;
          border: none;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          box-shadow: 0 0.5rem 0.5rem #00000015;
          transition: background-color 0.2s ease;
          margin-left: 2rem;

          > img {
            height: 30%;
          }

          &:first-child {
            margin-right: 2rem;

            > img {
              transform: rotate(180deg);
            }
          }

          &:disabled {
            background-color: #ffffff95;
            cursor: default;
          }
        }

        > .scroller {
          height: 75%;
          width: auto;
          flex: 1;
          display: flex;
          flex-direction: row;
          overflow-x: hidden;

          > a.item {
            width: 34%;
            min-width: 34%;
            height: 100%;
            display: flex;
            flex-direction: column;
            border-right: 1px solid #fff3;
            padding: 1rem 4rem;
            overflow-y: hidden;
            position: relative;
            text-decoration: none;

            &:last-child {
              border-right: none;
            }

            > .imagebox {
              width: calc(100% - 8rem);
              height: 85%;
              position: absolute;
              top: 0;
              left: 4rem;
              z-index: 0;

              &::after {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                content: "";
                background: linear-gradient(
                  rgba($secondaryColor, 0) 40%,
                  rgba($secondaryColor, 1)
                );
              }

              > img {
                width: auto;
                max-width: 100%;
                height: 100%;
                object-fit: contain;
                object-position: center;
              }
            }

            > .info {
              position: relative;
              z-index: 2;
              width: 100%;
              height: auto;
              display: flex;
              flex-direction: column;
              margin-top: auto;
              align-items: center;

              > .row {
                display: flex;
                flex-direction: row;
                width: auto;
                align-items: center;

                > p.large {
                  font-family: $tertiaryFont;
                  font-size: 3.5rem;
                  color: white;
                  margin: 0 0.5rem 0;
                }

                > p.name {
                  font-family: $quaternaryFont;
                  font-size: 1.5rem;
                  color: white;
                  text-transform: uppercase;
                  margin: 0;
                  text-align: start;
                  white-space: pre-wrap;
                  line-height: 0.95;
                }
              }

              > p.position {
                font-family: $quaternaryFont;
                font-size: 1rem;
                color: white;
                text-transform: uppercase;
                margin: 0;
                text-align: center;
              }
            }
          }
        }
      }
    }
  }

  @include low-width {
    > svg {
      display: none;
    }

    > .section-1-desktop {
      display: none;
    }

    > .section-1-mobile {
      width: 100%;
      height: auto;
      padding: 5rem 3rem;
      display: flex;
      flex-direction: column;
      background-color: $secondaryColor;

      > .group {
        width: auto;
        height: 40rem;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        position: relative;
        margin: 0 0 3rem;

        > .imagebox {
          position: absolute;
          height: 100%;
          display: flex;
          flex-direction: row;
          justify-content: center;
          width: 50%;
        }

        > p.number {
          font-family: $tertiaryFont;
          font-size: 8rem;
          color: $primaryColor;
          margin: 0;
          line-height: 0.8;
          text-align: center;
          margin: 0 auto 3rem;
          position: relative;
          z-index: 1;
        }
      }

      > h1 {
        font-family: $tertiaryFont;
        font-size: 5rem;
        margin: 0 0 5rem;
        color: white;
        font-weight: normal;
        line-height: 0.8;
      }

      > .row {
        width: 100%;
        display: flex;
        flex-direction: row;

        > .group {
          display: flex;
          flex-direction: column;

          &:first-child {
            width: 60%;
          }

          &:last-child {
            width: 40%;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            > svg {
              height: 10rem;
              width: auto;
              transform: rotate(-90deg);
              margin-bottom: 5rem;

              @keyframes blink {
                0% {
                  fill-opacity: 1;
                  r: 10;
                  stroke-opacity: 1;
                }
                40% {
                  stroke-opacity: 1;
                  fill-opacity: 0;
                }
                80% {
                  stroke-opacity: 0;
                  fill-opacity: 0;
                  r: 15;
                }
                81% {
                  stroke-opacity: 0;
                  fill-opacity: 0;
                  r: 3;
                }
                100% {
                  fill-opacity: 1;
                  stroke-opacity: 1;
                  r: 10;
                }
              }

              > circle {
                display: none;
              }

              &.left-raider {
                > circle {
                  &#left-raider {
                    display: block;
                    animation: blink 1.5s linear infinite;
                  }
                }
              }
              &.left-raider {
                > circle {
                  &#left-raider {
                    display: block;
                    animation: blink 1.5s linear infinite;
                  }
                }
              }
              &.left-raider {
                > circle {
                  &#left-raider {
                    display: block;
                    animation: blink 1.5s linear infinite;
                  }
                }
              }
              &.right-raider {
                > circle {
                  &#right-raider {
                    display: block;
                    animation: blink 1.5s linear infinite;
                  }
                }
              }
              &.left-cover {
                > circle {
                  &#left-cover {
                    display: block;
                    animation: blink 1.5s linear infinite;
                  }
                }
              }
              &.right-cover {
                > circle {
                  &#right-cover {
                    display: block;
                    animation: blink 1.5s linear infinite;
                  }
                }
              }
              &.left-corner {
                > circle {
                  &#left-corner {
                    display: block;
                    animation: blink 1.5s linear infinite;
                  }
                }
              }
              &.right-corner {
                > circle {
                  &#right-corner {
                    display: block;
                    animation: blink 1.5s linear infinite;
                  }
                }
              }
              &.all-rounder {
                > circle {
                  &#all-rounder {
                    display: block;
                    animation: blink 1.5s linear infinite;
                  }
                }
              }
            }

            > p.position {
              font-family: $primaryFont;
              font-size: 3rem;
              color: white;
              transform-origin: center;
              margin: 0;
            }
          }

          > .socials,
          .jersey {
            width: auto;
            height: auto;
            display: flex;
            flex-direction: column;

            > p {
              width: 100%;
              font-family: $primaryFont;
              font-size: 2rem;
              color: white;
              margin: 0 0 2rem;
              text-align: start;
            }

            > .links {
              width: auto;
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              margin-bottom: 3rem;

              > a {
                width: 4rem;
                height: 4rem;
                margin-right: 2rem;
                border-radius: 0.8rem;
                background-color: white;
                text-decoration: none;
                outline: none;
                border: none;
                cursor: pointer;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;

                &:last-child {
                  margin-right: 0;
                }

                > svg {
                  height: 50%;
                  width: auto;
                  max-width: 50%;
                  max-height: 50%;

                  * {
                    fill: $primaryColor;
                  }
                }
              }
            }
          }
        }
      }
    }

    > .section-2 {
      padding-bottom: 0;
      > .row {
        flex-direction: column;

        > .item {
          > p.title {
            font-size: 4rem;
            margin-bottom: 2rem;
          }

          > p.value {
            font-size: 2rem;
            margin-bottom: 4rem;
          }
        }
      }

      > .main {
        flex-direction: column;
        padding-top: 10rem;
        width: 100%;
        border-top: none;
        position: relative;

        &:before {
          position: absolute;
          width: 75%;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          content: "";
          border-top: 1px solid rgba(#707070, 0.4);
        }

        > img {
          width: 20rem;
          margin: 0;
          position: relative;
          z-index: 1;
        }

        > .content {
          margin-top: -3rem;
          background-color: $secondaryColor;
          padding: 10rem 3rem;

          > p.title {
            text-align: center;
            color: white;
            font-size: 4.5rem;
            margin-bottom: 5rem;
          }

          > p.text {
            color: white;
            font-size: 2rem;
            text-align: center;
          }
        }
      }
    }

    > .section-3 {
      > .title {
        height: 20rem;

        h2 {
          font-size: 6rem;
        }
      }

      > .main {
        padding: 15rem 3rem 0;

        > .item {
          width: 100%;
          flex-direction: column;
          margin-bottom: 15rem;

          > p.title {
            position: static;
            transform: none;
            font-size: 7rem;
            margin-bottom: 3rem;
          }
        }
      }
    }

    > .section-4 {
      > .title {
        height: 20rem;

        h2 {
          font-size: 6rem;
        }
      }

      > .main {
        > .inner {
          padding: 2rem 4rem;

          > button {
            display: none;
          }

          > .scroller {
            overflow-x: auto;

            > a.item {
              width: 22rem;
              min-width: 22rem;
            }
          }
        }
      }
    }
  }
}
