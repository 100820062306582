@import '../../shared.scss';

.terms-and-privacy-page{
    width: 100%;
    min-height: $minFullScreenHeight;
    padding: $headerHeight 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    > *{
        width: 100%;
        max-width: $innerMaxWidth;
    }

    > h1{
        font-family: $secondaryFont;
        font-size: 3rem;
        margin: 1rem 0 2rem;
        text-align: start;
    }

    > h2{
        font-family: $secondaryFont;
        font-size: 2.5rem;
        margin: 0.8rem 0 1.5rem;
        text-align: start;
    }

    > h3{
        font-family: $secondaryFont;
        font-size: 2rem;
        margin: 0.5rem 0 1rem;
        text-align: start;
    }

    > p{
        font-family: $secondaryFont;
        font-size: 1rem;
        margin: 0 0 1rem;
        text-align: start;
    }

    li{
        font-family: $secondaryFont;
        font-size: 1rem;
        margin: 0 0 1rem;
        text-align: start;
    }

    > h1.title{
        font-family: $primaryFont;
        font-size: 4rem;
        color: $secondaryColor;
        text-align: start;
        margin: 5rem 0 5rem;
        font-weight: normal;
    }

    @include low-width{
        > h1{
            font-size: 4rem;
            margin: 1.5rem 0 3rem;
            text-align: start;
        }
    
        > h2{
            font-family: $secondaryFont;
            font-size: 3rem;
            margin: 1.2rem 0 2rem;
            text-align: start;
        }
    
        > h3{
            font-family: $secondaryFont;
            font-size: 2.5rem;
            margin: 1rem 0 1.5rem;
            text-align: start;
        }
    
        > p{
            font-family: $secondaryFont;
            font-size: 1.7rem;
            margin: 0 0 1.5rem;
            text-align: start;
        }

        li{
            font-family: $secondaryFont;
            font-size: 1.7rem;
            margin: 0 0 1.5rem;
            text-align: start;
        }
    
        > h1.title{
            font-family: $primaryFont;
            font-size: 6rem;
            color: $secondaryColor;
            text-align: start;
            margin: 5rem 0 5rem;
            font-weight: normal;
        }
    }
}