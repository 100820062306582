@import '.../../../../shared.scss';

.match-stat-item{
    --width: 50rem;
    width: var(--width);
    margin-bottom: 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    > p.title{
        width: calc(var(--width) * 0.8);
        text-align: center;
        font-family: $tertiaryFont;
        font-size: 2rem;
        background: url(../../Assets/stripe-background.svg);
        padding: 0.5rem 0;
        margin: 0;
        color: $secondaryColor;
    }

    > .main{
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 0.5rem 5rem;
        background-color: $secondaryColor;
        position: relative;

        > p.value{
            font-family: $primaryFont;
            font-size: 3rem;
            color: white;
            line-height: 0.9;
            margin: 0.5rem 0 0;
        }

        > .indicator{
            width: calc(var(--width)*0.5);
            height: 0.5rem;
            border-radius: 0.25rem;
            background-color: rgba(#EEEEEE, 0.2);
            position: absolute;
            overflow: hidden;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            top: 50%;

            > div{
                position: absolute;
                height: 100%;
                top: 0;
                left: 0;
                background-color: $primaryColor;
            }
        }
    }

    > .form{
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 0.5rem 5rem;
        background-color: $secondaryColor;
        position: relative;

        > .item{
            width: 50%;
            display: flex;
            flex-direction: row;

            &:last-child{
                flex-direction: row-reverse;

                > .indicators{
                    flex-direction: row-reverse;
                }
            }

            > .stats{
                display: flex;
                flex-direction: row;

                > .item{
                    display: flex;
                    flex-direction: column;
                    margin: 0 0.5rem;

                    > p.label{
                        font-family: $primaryFont;
                        font-size: 1rem;
                        color: white;
                        margin: 0 0 1rem;
                        text-align: center;
                    }

                    > p.value{
                        font-family: $primaryFont;
                        font-size: 2rem;
                        color: white;
                        margin: 0;
                    }
                }
            }

            > .indicators{
                display: flex;
                flex-direction: row;
                flex: 1;
                align-items: center;
                justify-content: center;
                
                > .item{
                    height: 0.6rem;
                    width: 0.6rem;
                    min-height: 0.6rem;
                    min-width: 0.6rem;
                    margin: 0.3rem;
                    position: relative;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    overflow: visible;

                    > svg{
                        width: 0.6rem;
                        height: 0.6rem;
                        > circle{
                            fill: white;
                        }
                    }

                    &.pink{
                        > svg{
                            > circle{
                                fill: $primaryColor;
                            }
                        }
                    }

                    &.draw{
                        &::after{
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            width: 70%;
                            height: 1px;
                            background-color: white;
                            content: "";
                        }
                    }
                }
            }
        }
    }

    @include low-width{
        --width: 35rem;

        > p.title{
            font-size: 3.5rem;
            padding: 1rem 0;
        }

        > .main{
            padding: 1rem 5rem;

            > p.value{
                font-size: 4rem;
            }

            > .indicator{
                width: calc(var(--width) * 0.4);
            }
        }

        > .form{
            height: 6rem;
            padding: 0 4rem;

            > .item{
                position: relative;
                height: 100%;

                &:first-child::after{
                    position: absolute;
                    width: 0;
                    height: 70%;
                    top: 50%;
                    right: 0;
                    transform: translateY(-50%);
                    border-right: 1px solid rgba(white, 0.3);
                    content: "";
                }

                > .stats{
                    display: none;
                }

                > .indicators{
                    width: 100%;
                    justify-content: flex-start;

                    > .item{
                        height: 1.3rem;
                        width: 1.3rem;
                        min-height: 1.3rem;
                        min-width: 1.3rem;
                        margin: 0.4rem;
                    }
                }
            }
        }
    }
}