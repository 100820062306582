@import "../../shared.scss";

.fixture {
  --numberShown: 3;
  // sync width here with button control in homepage.js
  width: calc(100% / (var(--numberShown) + 0.3));
  min-width: calc(100% / (var(--numberShown) + 0.3));
  display: flex;
  flex-direction: column;
  height: 35rem;
  margin-right: calc(
    100% * (0.3 / (var(--numberShown) + 0.3) / (var(--numberShown) - 1))
  );
  background-color: white;

  &:last-child {
    margin-right: 0;
  }

  > .info {
    height: auto;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 2rem 2rem 0;

    > .title {
      display: flex;
      flex-direction: column;
      width: 100%;
      border-bottom: 1px solid $secondaryColor;

      > p {
        font-family: $primaryFont;
        font-size: 1.8rem;
        color: $secondaryColor;
        margin: 0 0 0.8rem;
        text-align: start;
      }

      > .row {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        > p {
          font-family: $primaryFont;
          font-size: 1.3rem;
          color: $secondaryColor;
          margin: 0 0 0.5rem;
        }
      }
    }

    > .main {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex-grow: 1;
      height: auto;

      .teams {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        margin-bottom: 5rem;
        padding: 0 1rem;
        align-items: center;

        > .item {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: 35%;
          height: 100%;

          > p {
            font-family: $primaryFont;
            font-size: 1rem;
            color: $secondaryColor;
            margin: 0 0 0.3rem;
            text-transform: uppercase;
            white-space: nowrap;
          }

          > img {
            width: 100%;
            height: auto;
          }
        }

        > p.score {
          font-family: $primaryFont;
          font-size: 2rem;
          color: $secondaryColor;
        }
      }

      > .timer {
        width: 100%;
        height: 2.5rem;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        .time {
          overflow-y: hidden;
          height: 100%;
          width: 1rem;
          position: relative;
          &.day {
            width: 2rem;
          }
          > p {
            font-family: $primaryFont;
            font-size: 2.5rem;
            color: white;
            margin: 0;
            position: absolute;
          }
        }

        > p.unit {
          font-family: $primaryFont;
          font-size: 1.3rem;
          color: $primaryColor;
          margin: 0 0.5rem;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }

  > .buttons {
    width: 100%;
    height: 5rem;
    display: flex;
    flex-direction: row;
    border-top: 1px solid #04358d30;

    > a {
      height: 100%;
      width: auto;
      outline: none;
      border: none;
      background-color: white;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding: 0;
      text-decoration: none;

      &:hover {
        filter: brightness(1.3);
      }

      &.hub {
        width: auto;
        flex: 1;

        > svg {
          height: 20%;
          margin-right: 0.5rem;
          transform: rotate(90deg);
        }

        > svg,
        > svg * {
          fill: $secondaryColor;
        }

        > p {
          font-family: $primaryFont;
          font-size: 1.5rem;
          color: $secondaryColor;
          margin: 0;
        }
      }

      &.stream {
        width: auto;
        flex: 1;
        background-color: $secondaryColor;
        > img {
          height: 50%;
          margin-right: 0.8rem;
        }

        > p {
          font-family: $primaryFont;
          font-size: 1.5rem;
          color: white;
          margin: 0;
        }
      }
    }
  }

  &.upcoming {
    background-color: $secondaryColor;
    > .info {
      > .title {
        border-bottom: 1px solid white;
        > p {
          color: white;
        }
        > .row {
          > p {
            color: white;
          }
        }
      }

      > .main {
        .teams {
          > .item {
            > p {
              color: white;
            }
          }
          > p.score {
            color: white;
          }

          > p.vs-text {
            font-family: $quaternaryFont;
            font-size: 2rem;
            color: white;
          }
        }
      }
    }

    > .buttons {
      > a {
        background-color: $primaryColor;
        width: 100%;
        text-decoration: none;

        &:hover {
          filter: brightness(1.05);
        }

        &.stats {
          > p {
            font-family: $primaryFont;
            font-size: 1.5rem;
            color: white;
            margin: 0;
          }

          > svg {
            height: 40%;
            margin-right: 1rem;
          }
        }
      }
    }
  }

  @include low-width {
    width: 35rem;
    min-width: 35rem;
    height: 48rem;
    margin-right: 2rem;

    &:first-child {
      margin-left: 2rem;
    }

    &:last-child {
      margin-right: 2rem;
    }

    > .info {
      padding: 4rem 2rem 0;

      > .title {
        > p {
          font-size: 3rem;
          margin-bottom: 1rem;
        }

        > .row {
          > p {
            font-size: 2.5rem;
            margin-bottom: 1.5rem;
          }
        }
      }

      > .main {
        > .teams {
          align-items: center;
          > .item {
            width: 30%;
            > p {
              font-size: 1.8rem;
              text-align: center;
              margin-bottom: 1.5rem;
              margin-left: -10px;
            }
          }

          > p.score {
            font-size: 4rem;
            margin: auto 0.5rem;
          }
        }

        > .timer {
          height: 4rem;
          > .time {
            width: 1.7rem;
            &.day {
              width: 3.5rem;
            }
            > p {
              font-size: 4rem;
            }
          }

          > p.unit {
            font-size: 2.5rem;
          }
        }
      }
    }
  }
}
