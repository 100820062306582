@import '../../shared.scss';

.squad-page{
    width: 100%;
    height: auto;
    min-height: $minFullScreenHeight;
    padding-top: var(--headerHeight);
    
    >.section-1{
        position: relative;
        width: 100%;
        height: 32rem;
        display: flex;
        flex-direction: column;
        justify-content: center;

        &::after{
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            background-color: $secondaryColor;
            opacity: 0.7;
            z-index: 1;
        }

        > img{
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 0;
            object-fit: cover;
            object-position: center;
        }

        > h1{
            font-family: $tertiaryFont;
            font-size: 5.5rem;
            font-weight: normal;
            color: white;
            margin: 0 0 1.5rem;
            position: relative;
            z-index: 2;
            line-height: 0.8;
            letter-spacing: 0.1rem;
        }

        > h3{
            font-family: $tertiaryFont;
            font-size: 3rem;
            font-weight: normal;
            color: white;
            margin: 0;
            position: relative;
            z-index: 2;
            letter-spacing: 0.1rem;
        }
    }

    > .section-2{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        margin-bottom: 5rem;

        > .subsection{
            width: 100%;
            height: auto;
            display: flex;
            flex-direction: column;
            background-image: url(../../Assets/spotted-background.png);
            background-repeat: no-repeat repeat;
            
            > .title{
                width: 100%;
                height: 17.5rem;
                background-color: white;
                background-image: url(../../Assets/stripe-background.svg);
                background-repeat: repeat;
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-bottom: 10rem;
                
                > h2{
                    width: 100%;
                    margin: 0;
                    line-height: 0.8;
                    font-family: $tertiaryFont;
                    font-size: 4.5rem;
                    color: $secondaryColor;
                    text-align: center;
                    font-weight: normal;
                    letter-spacing: 0.1rem;
                }
            }

            > .main{
                width: 100%;
                max-width: $innerMaxWidth;
                height: auto;
                margin: 0 auto 5rem;
                
                > .row{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;
                    height: 28rem;
                    margin-bottom: 9rem;
                    
                    > .item {
                        width: calc((100% - 20rem)/3);
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        cursor: pointer;
                        transform-origin: center bottom;
                        transition: transform 0.2s ease;
                        text-decoration: none;

                        &:hover{
                            transform: scale(1.1);
                        }
                        
                        > .imagebox{
                            position: relative;
                            width: 100%;
                            height: 64%;
    
                            > .number{
                                width: 50%;
                                height: 90%;
                                display: flex;
                                flex-direction: column;
                                justify-content: flex-start;
                                background-color: $primaryColor;
                                position: absolute;
                                bottom: 0;
                                right: 0;
                                z-index: 0;
    
                                > p{
                                    text-align: center;
                                    font-family: $tertiaryFont;
                                    font-size: 3.5rem;
                                    color: white;
                                    margin: 1rem 0;
                                }
                            }
    
                            > img{
                                position: relative;
                                height: 100%;
                                width: 75%;
                                object-fit: cover;
                                object-position: top center;
                                z-index: 1;
                            }
    
                        }
                        
                        > .textbox{
                            width: 100%;
                            height: 24%;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            position: relative;
                            background-image: url(../../Assets/Squad/trapezoid.svg);
                            background-repeat: no-repeat;
                            background-size: 100% 100%;
                            
    
                            > p{
                                font-family: $quaternaryFont;
                                font-size: 1.1rem;
                                color: white;
                                line-height: 0.9;
                                text-align: center;
                                margin: 0 0 0.5rem;
                                z-index: 1;
    
                                &.position{
                                    font-size: 0.7rem;
                                    margin: 0.5rem 0 0;
                                }
                            }
                        }
                    }
                }

            }
        }
    }

    @include low-width{
        > .section-1{
            height: 64rem;

            > h1{
                font-size: 7rem;
                margin-bottom: 3rem;
            }

            > h3{
                font-size: 5rem;
            }
        }

        > .section-2{
            
            > .subsection{
                
                > .title{
                    height: 22rem;
                    margin-bottom: 15rem;

                    > h2{
                        font-size: 7rem;
                    }
                }

                > .main{
                    > .row{
                        padding: 0 3rem;
                        flex-direction: column;
                        align-items: center;
                        height: auto;
                        margin-bottom: 0;

                        > .item{
                            width: 35rem;
                            height: 50rem;
                            margin-bottom: 15rem;

                            > .imagebox{
                                > .number{
                                    > p{
                                        font-size: 6rem;
                                        margin: 3rem 0;
                                    }
                                }
                            }

                            > .textbox{
                                > p{
                                    font-size: 2rem;
                                    margin-bottom: 1rem;

                                    &.position{
                                        font-size: 1.3rem;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}