@import '../../shared.scss';

.login{
    width: 40rem;
    height: 40rem;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;

    &:before{
        position: absolute;
        top: 0;
        left: 0;
        width: 50%;
        height: 4rem;
        background: url(../../Assets/stripe-background.svg);
        content: "";
    }
    
    &:after{
        position: absolute;
        bottom: 0;
        right: 0;
        width: 50%;
        height: 4rem;
        background: url(../../Assets/stripe-background.svg);
        content: "";
    }

    > button{
        position: absolute;
        top: 1.5rem;
        right: 2.5rem;
        padding: 0;
        width: 2.5rem;
        height: 2.5rem;
        min-width: 2.5rem;
        min-height: 2.5rem;
        max-width: 2.5rem;
        max-height: 2.5rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border-radius: 1.5rem;
        background-color: white;
        outline: none;
        border: none;
        box-shadow: 0 0.3rem 0.3rem #00000020;
        cursor: pointer;
        
        > svg{
            width: 50%;
            height: 50%;

            *{
                fill: black;
                stroke: black;
            }
        }
    }

    > .page-one{
        width: auto;
        display: flex;
        flex-direction: column;
        align-items: center;

        > img{
            height: 8rem;
            width: auto;
            object-fit: contain;
            object-position: center;
            margin-bottom: 2rem;
        }
    
        > h1{
            font-family: $tertiaryFont;
            font-size: 2.5rem;
            color: $secondaryColor;
            margin: 0 0 2rem;
            font-weight: normal;
        }
    
        > p{
            font-family: $primaryFont;
            font-size: 1rem;
            color: black;
            opacity: 0.5;
            margin: 0 0 2rem;
            text-align: center;
        }
    
        > .row{
            display: flex;
            flex-direction: row;
    
            > button{
                width: 4rem;
                height: 4rem;
                outline: none;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                margin: 0 0.75rem;
    
                &.facebook{
                    border: 1px solid #3B5998;
                    background-color: #3B5998;
                }
    
                &.google{
                    border: 1px solid #D9D9D9;
                    background-color: white;
                }
            }
        }
    }

    > .page-two{
        width: auto;
        display: flex;
        flex-direction: column;
        align-items: center;


        > img{
            width: 8rem;
            height: 8rem;
            border-radius: 50%;
            margin-bottom: 2rem;
            background-color: black;
        }

        > input{
            width: 15rem;
            font-family: $primaryFont;
            font-size: 1.3rem;
            color: $secondaryColor;
            padding: 0.8rem 1rem 0.5rem;
            border-radius: 0.4rem;
            border: 1px solid #B7B7B7;
            margin: 1.5rem 0;
            outline: none;

            &:disabled{
                opacity: 0.5;
            }
        }

        > .MuiInputBase-root{
            width: 100% !important;
            height: 2rem !important;
            outline: none !important;
            
            *{
                outline: none !important;
            }
        }

        > button{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            width: 15rem;
            background-color: $secondaryColor;
            border-radius: 0.5rem;
            cursor: pointer;
            padding: 0.5rem 0;
            margin-top: 3rem;
            outline: none;
            border: none;

            &:disabled{
                cursor: default;
                opacity: 0.5;
            }
            
            > p{
                font-family: $primaryFont;
                font-size: 1.5rem;
                color: white;
                margin: 0.5rem 1.5rem 0 0;
            }

            > svg{
                height: 1.3rem;
                width: auto;
                *{
                    fill: white;
                }
            }
        }
    }
    @include low-width{
        width: 40rem;

        > button{
            width: 4rem;
            height: 4rem;
            min-width: 4rem;
            min-height: 4rem;
            max-width: 4rem;
            max-height: 4rem;
            border-radius: 2rem;
        }
    }

}