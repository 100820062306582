@import "../../shared.scss";

.news-item-page {
  width: 100%;
  min-height: $minFullScreenHeight;
  padding: $headerHeight 5rem;

  > .section-1 {
    width: 100%;
    max-width: $innerMaxWidth;
    margin: 5rem auto;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    min-height: 25rem;

    > .text {
      display: flex;
      flex-direction: column;
      width: auto;
      margin-right: 2rem;
      max-width: 50%;

      > h1 {
        font-family: $primaryFont;
        font-size: 4rem;
        color: $secondaryColor;
        margin: 0 0 1rem;
        text-align: start;
        font-weight: normal;
      }

      > .row {
        display: flex;
        flex-direction: row;
        align-items: center;

        > p.date {
          font-family: $secondaryFont;
          font-size: 1rem;
          color: $secondaryColor;
          margin: 0 2rem 0 0;
        }

        > p.tag {
          font-family: $secondaryFont;
          font-size: 0.8rem;
          color: $secondaryColor;
          padding: 0.3rem 1rem;
          letter-spacing: 0.1rem;
          text-transform: uppercase;
          border: 1px solid $secondaryColor;
          border-radius: 0.3rem;
        }
      }
    }

    > img {
      height: 100%;
      max-height: 30rem;
      width: auto;
      max-width: calc(60% - 4rem);
      object-fit: contain;
      object-position: center;
    }
  }

  > .section-2 {
    width: 60%;
    min-width: 35rem;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 2rem 0;

    &::before {
      position: absolute;
      height: 4px;
      width: 30%;
      top: 0;
      left: 0;
      background-color: $secondaryColor;
      border-radius: 2px;
      content: "";
      z-index: 2;
    }

    > h1 {
      font-family: $secondaryFont;
      font-size: 3rem;
      margin: 1rem 0 2.5rem;
      text-align: start;
      color: black;
    }

    > h2 {
      font-family: $secondaryFont;
      font-size: 2.5rem;
      margin: 0.8rem 0 2rem;
      text-align: start;
      color: black;
    }

    > h3 {
      font-family: $secondaryFont;
      font-size: 2rem;
      margin: 0.5rem 0 1.5rem;
      text-align: start;
      color: black;
    }

    > p {
      font-family: $secondaryFont;
      font-size: 1rem;
      margin: 0 0 2rem;
      text-align: start;
      color: black;
    }

    > p.subtitle {
      font-family: $secondaryFont;
      font-size: 1.2rem;
      color: $secondaryColor;
      text-align: start;
    }
  }

  @include low-width {
    > .section-1 {
      flex-direction: column;

      > .text {
        width: 100;
        max-width: 100%;
        margin-right: 0;
        margin-bottom: 2rem;

        > h1 {
          font-size: 5rem;
        }

        > .row {
          > p.date {
            font-size: 1.5rem;
          }

          > p.tag {
            font-size: 1.2rem;
          }
        }
      }

      > img {
        max-width: 100%;
      }
    }

    > .section-2 {
      width: 100%;

      > h1 {
        font-size: 3rem;
        margin: 1rem 0 2rem;
      }

      > h2 {
        font-size: 2.5rem;
        margin: 1rem 0 2rem;
      }

      > h3 {
        font-size: 2.2rem;
        margin: 1rem 0 2rem;
      }

      > p {
        font-size: 1.5rem;
        margin: 0 0 1rem;
      }

      > p.subtitle {
        font-size: 2rem;
      }
    }
  }
}
