@import '../../shared.scss';

.navigation-drawer{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 6rem 0;
    align-items: flex-start;

    > .row{
        width: 100%;
        padding: 0 5rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 5rem;

        > img{
            height: 10rem;
            width: auto;
            object-fit: contain;
            object-position: center;
        }

        > button{
            height: 4rem;
            width: 4rem;
            border-radius: 2rem;
            border: none;
            outline: none;
            display: flex;
            align-items: center;
            justify-content: center;
            background: none;
            box-shadow: 0 0.5rem 0.7rem #00000020;
            padding: 0;
            
            > svg{
                width: 60%;
                height: auto;

                *{
                    fill: black;
                    stroke: black;
                }
            }
        }
    }

    > p.title{
        font-family: $tertiaryFont;
        font-size: 10rem;
        color: $secondaryColor;
        padding: 2rem 5rem;
        margin: 0 0 5rem;
        background: url(../../Assets/stripe-background.svg);
    }

    > .links{
        width: 100%;
        padding: 0 5rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        > a{
            font-family: $primaryFont;
            font-size: 3rem;
            color: black;
            text-decoration: none;
            margin: 0 0 2rem;
        }
    }
}