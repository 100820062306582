@import '../../shared.scss';

.footer{
    width: 100%;
    height: auto;
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: $secondaryColor;

    > img{
        height: 8rem;
        width: auto;
        object-position: center;
        object-fit: contain;
        left: 50%;
        top: 0;
        transform: translate3d(-50%, -50%, 0);
        position: absolute;
        z-index: 4;
    }

    > .section-1{
        width: 100%;
        padding: 15rem 0 0;
        margin: 0 0 5rem;

        > p.light{
            width: 100%;
            text-align: center;
            margin: 0 0 2rem;
            font-family: $primaryFont;
            font-size: 1.3rem;
            color: rgba(white, 0.8);            
        }

        > .socials{
            width: 100%;
            margin: 0 0 5rem;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            > a{
                height: 2rem;
                width: auto;
                margin: 0 3rem 0 0;
                background: none;
                outline: none;
                border: none;
                text-decoration: none;

                &:last-child{
                    margin-right: 0;
                }

                > img{
                    height: 100%;
                    width: auto;
                    object-fit: contain;
                    object-position: center;
                }
            }
        }

        > p.copyright{
            width: 100%;
            font-family: $primaryFont;
            font-size: 1.5rem;
            color: white;
            margin: 0 0 3rem;
            text-align: center;
        }
    }

    > .section-2{
        width: 100%;
        max-width: $innerMaxWidth;
        display: flex;
        flex-direction: column;
        margin: 0 auto;

        > p{
            font-family: $primaryFont;
            font-size: 2rem;
            color: white;
            width: 100%;
            margin: 0 0 3rem;
            padding: 0 0 1.5rem;
            border-bottom: 1px solid rgba(white, 0.3);
            text-align: start;
        }

        > .row{
            width: 100%;
            height: 18rem;
            margin-bottom: 5rem;
            display: flex;
            flex-direction: row;

            > .links{
                width: auto;
                flex: 1;
                height: auto;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                
                > .item{
                    width: 50%;
                    
                    > a{
                        font-family: $primaryFont;
                        text-transform: uppercase;
                        font-size: 1rem;
                        color: white;
                        text-align: start;
                        text-decoration: none;
                        background: none;
                        outline: none;
                        border: none;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                    }
                }
                
            }
            
            > .mobile-links{
                border-left: 1px solid rgba(white, 0.3);
                width: auto;
                flex: 1;
                height: 100%;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;

                > img{
                    height: 80%;
                    width: auto;
                    object-fit: contain;
                    object-position: center;
                    margin-right: 4rem;
                }

                > .column{
                    display: flex;
                    flex-direction: column;

                    > p{
                        font-family: $primaryFont;
                        text-transform: uppercase;
                        font-size: 1.3rem;
                        color: white;
                        text-align: start;
                        margin: 0 0 2rem;
                        text-align: start;                        
                    }

                    > .row{
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        align-items: center;

                        > a{
                            height: 4rem;
                            width: auto;
                            margin-right: 2rem;
                            background: none;
                            outline: none;
                            border: none;
                            text-decoration: none;

                            > img{
                                height: 100%;
                                width: auto;
                                object-fit: contain;
                                object-position: center;
                            }
                        }
                    }
                }
            }
        }
    }

    > .section-3{
        width: 100%;
        height: 3rem;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-bottom: 2rem;

        > img{
            height: 60%;
            width: auto;
            object-fit: contain;
            object-position: center;
            margin-right: 3rem;
            margin-top: 0.4rem;
        }
        
        > a{
            height: 60%;
            text-decoration: none;
            cursor: pointer;
            margin-right: 3rem;

            &.medium{
                height: 70%;
            }

            &.large{
                height: 100%;
            }

            &:last-child{
                margin-right: none;
            }

            > img{
                height: 100%;
                width: auto;
                object-fit: contain;
                object-position: center;
            }
        }
    }

    @include low-width{
        >.section-1{
            > p.light{
                font-size: 1.8rem;
            }

            > .socials{
                justify-content: space-evenly;
                width: 80%;
                margin: 2rem auto 5rem;
                > a{
                    height: 3rem;
                }
            }
        }
        
        > .section-2{
            padding: 0 2rem;

            > p{
                font-size: 3rem;
                margin-bottom: 5rem;
            }

            > .row{
                height: auto;
                flex-direction: column;
                margin-bottom: 2rem;

                > .links{
                    width: 100%;
                    // margin-bottom: 5rem;
                    
                    > .item{
                        > a{
                            font-size: 1.8rem;
                            margin-bottom: 3rem;
                            font-family: $primaryFont;
                            text-transform: uppercase;
                        }
                    }
                    
                }
                
                > .mobile-links{
                    border-left: none;
                    width: 100%;

                    > img{
                        width: 10rem;
                        height: auto;
                        margin-right: 5rem;
                    }

                    > .column{
                        width: 50%;
                        > p{
                            font-size: 2rem;
                            font-family: $primaryFont;
                            text-transform: uppercase;
                            white-space: pre-wrap;
                            line-break: normal;
                        }
                    }
                }
            }
        }

        > .section-3{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            padding: 2rem;
            height: auto;
            justify-content: flex-start;

            > img{
                width: 100%;
                height: 2.5rem;
                object-fit: contain;
                object-position: left center;
                margin-bottom: 3rem;
            }

            > a{
                height: 2.2rem;

                &.medium{
                    height: 2.7rem;
                }

                &.large{
                    height: 3.5rem;
                }
            }
        }
    }
}