@import "../../shared.scss";

.header {
  --headerHeight: 9rem;
  width: 100%;
  height: var(--headerHeight);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 0 0 2rem;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
  background-color: white;
  transition: top 0.4s ease, background-color 0.3s ease;
  box-shadow: 0 0 1rem #00000010;

  // &.invisible{
  //     top: calc(-1 * var(--headerHeight));
  // }

  &.transparent {
    justify-content: space-between;
    background: none;
    transition: background-color 0.3s ease;
    box-shadow: none;

    &.invisible {
      top: 0;
    }

    > .inner-links,
    > .sponsor,
    > button {
      opacity: 0;
      pointer-events: none;
      transition: opacity 0.3s ease;
    }
  }

  > a.logo {
    height: calc(100% - 4rem);
    cursor: pointer;
    margin: 2rem;

    > img {
      height: 100%;
      width: auto;
    }
  }

  > button {
    height: 100%;
    background: none;
    outline: none;
    border: none;
    cursor: pointer;
    width: var(--headerHeight);
    position: relative;
    overflow: visible;
    border-left: 1px solid rgba(black, 0.1);

    &.sidenav {
      display: none;
    }

    > svg {
      width: auto;
      height: 2.3rem;
      object-fit: contain;
      object-position: center;

      > * {
        transition: fill 0.2s ease;
      }
    }

    > img {
      height: 3.8rem;
      width: 3.8rem;
      border-radius: 50%;
    }

    > .profile-hover {
      width: 20rem;
      height: auto;
      border-radius: 1rem;
      position: absolute;
      top: 90%;
      right: 3rem;
      box-shadow: 0 0.5rem 0.8rem #00000030;
      z-index: 3;
      background-color: white;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 3rem 0;
      transform-origin: top right;
      transform: scale(0);
      transition: transform 0.2s ease 0.2s;
      cursor: default;

      > * {
        opacity: 0;
        transition: opacity 0.2s ease;
      }

      &.open {
        transition: transform 0.2s ease;
        transform: scale(1);

        > * {
          opacity: 1;
        }
      }

      > img {
        width: 5rem;
        height: 5rem;
        border-radius: 50%;
        object-position: center;
        object-fit: cover;
        margin-bottom: 1rem;
      }

      > p {
        font-family: $primaryFont;
        font-size: 1.5rem;
        color: $secondaryColor;
        margin: 0 0 2rem;
        text-transform: uppercase;
      }

      > button {
        width: 10rem;
        padding: 0.8rem 0 0.5rem;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background-color: white;
        outline: none;
        border: 1px solid #00000040;
        margin-bottom: 1rem;
        border-radius: 0.3rem;
        font-family: $primaryFont;
        font-size: 1rem;
        color: black;
        cursor: pointer;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  @include high-width {
    > .inner-links {
      display: flex;
      flex-direction: row;
      margin-left: auto;
      transition: opacity 0.2s ease 0.2s;
      height: 100%;
      align-items: center;

      > a {
        width: auto;
        min-width: auto;
        height: auto;
        display: flex;
        padding: 0.5rem 1.7rem 0;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        letter-spacing: 0.06rem;
        font-family: $tertiaryFont;
        font-size: 1.2rem;
        color: black;
        cursor: pointer;
        text-decoration: none;
        transition: color 0.2s ease;

        &:last-child {
          margin-right: 1.5rem;
        }

        &:hover,
        &.active {
          color: $primaryColor;
        }
      }
    }

    > .sponsor {
      display: flex;
      height: 100%;
      flex-direction: column;
      align-items: center;
      padding: 1rem 2.5rem;
      border-left: 1px solid rgba(black, 0.1);
      cursor: pointer;
      transition: opacity 0.2s ease 0.2s;
      width: auto;
      min-width: fit-content;
      text-decoration: none;

      > p {
        font-family: $primaryFont;
        text-transform: uppercase;
        color: $secondaryColor;
        font-size: 0.85rem;
        margin: 1rem 0 0.6rem;
        text-align: center;
      }

      > img {
        height: 2.5rem;
        min-height: 2.5rem;
        // flex-grow: 1;
        width: auto;
        object-fit: contain;
        object-position: center;
      }
    }
  }

  @include medium-and-below {
    justify-content: space-between;

    > .inner-links {
      display: none;
    }

    > .sponsor {
      display: none;
    }

    > button {
      &:nth-child(4) {
        margin-left: auto;
      }

      > .profile-hover {
        width: 25rem;
        height: auto;
        right: 0;

        > img {
          width: 7rem;
          height: 7rem;
          border-radius: 50%;
          object-position: center;
          object-fit: cover;
          margin-bottom: 3rem;
        }

        > p {
          font-family: $primaryFont;
          font-size: 2rem;
          color: $secondaryColor;
          margin: 0 0 3rem;
          text-transform: uppercase;
        }

        > button {
          width: 15rem;
          padding: 1.2rem 0 1rem;
          margin-bottom: 2rem;
          border-radius: 0.5rem;
          font-size: 2rem;
        }
      }

      &.sidenav {
        display: block;

        > svg {
          height: 1.5rem;
        }
      }
    }
  }
}
