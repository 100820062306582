@import '../../shared.scss';

.loading-page{
    width: 100vw;
    height: 100vh;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    overflow: hidden;
    z-index: 21;
    background-color: white;

    > .main{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: auto;
        height: auto;
        position: relative;
        bottom: 5rem;

        > img{
            height: 10rem;
            width: auto;
            object-fit: contain;
            object-position: center;
            margin-bottom: 5rem;
        }

        > .progress{
            width: 30rem;
            height: 0.5rem;
            background-color: #F5F5F5;
            border-radius: 0.15rem;
            overflow: hidden;
            margin-bottom: 2rem;

            > .indicator{
                height: 100%;
                background-color: $primaryColor;
                transition: width 0.4s ease;
            }
        }

        > p{
            font-family: $primaryFont;
            font-size: 2rem;
            color: #4ECAF5;
            margin: 0;
        }
    }

    > .loading-graphic{
        width: 100%;
        height: 30%;
        position: absolute;
        bottom: 0;
        left: 50%;
        background-image: url(../../Assets/loading-screen.svg);
        background-size: contain;
        background-repeat: repeat-x;  
        transform: translateX(-50%);
    }
}