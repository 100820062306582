@import '../../shared.scss';

.player-stat-card{
    $cardSubItemWidth: 15rem;
    width: 48rem;
    min-height: 25.5rem;
    background-color: $secondaryColor;
    border-radius: 1rem;
    padding: 2.5rem 3.5rem;
    display: flex;
    flex-direction: column;

    > .row-1{
        width: 100%;
        display: flex;
        height: auto;
        flex: 1;
        flex-direction: row;
        justify-content: space-between;

        > .number-item{
            width: $cardSubItemWidth;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            > p.title{
                font-family: $primaryFont;
                font-size: 2.3rem;
                color: white;
                margin: 0 0 2.5rem;
                padding: 0 0 0.8rem;
                position: relative;;

                &:after{
                    position: absolute;
                    content: "";
                    width: 110%;
                    left: 0;
                    bottom: 0;
                    height: 0;
                    border-top: 1.5px solid white;
                }
            }

            > p.value{
                font-family: $tertiaryFont;
                font-size: 8rem;
                color: $primaryColor;
                line-height: 0.8;
                margin: 0;
            }
        }

        > .percent-item{
            width: $cardSubItemWidth;
            height: 100%;
            padding-top: 2rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            
            > p.title{
                font-family: $primaryFont;
                font-size: 1rem;
                color: white;
                opacity: 0.4;
                margin: 0 0 1rem;
            }

            > .indicator{
                width: 9rem;
                height: 9rem;
                border-radius: 50%;
                position: relative;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;

                > p{
                    font-family: $tertiaryFont;
                    font-size: 2rem;
                    color: white;
                    text-align: center;
                    margin: 0;
                    position: relative;
                    z-index: 1;
                }
                
                > svg{
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 0;
                    overflow: visible;
                }
            }

        }
    }

    > .row-2{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: 0.5rem;
        
        > .item{
            width: $cardSubItemWidth;
            height: auto;
            display: flex;
            flex-direction: column;
            margin-top: 1.3rem;

            > .title{
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: flex-end;
                margin-bottom: 1rem;

                > p.label{
                    font-family: $primaryFont;
                    font-size: 1rem;
                    color: white;
                    opacity: 0.4;
                    margin: 0;
                }
                
                > p.value{
                    font-family: $primaryFont;
                    font-size: 1.5rem;
                    color: white;
                    margin: 0;
                }
            }

            > .indicator{
                height: 8px;
                width: 100%;
                border-radius: 4px;
                background-color: rgba(#EEEEEE, 0.2);
                overflow: hidden;

                > .value{
                    height: 100%;
                    background-color: $primaryColor;
                }
            }
        }

    }

    @include low-width{
        width: 100%;
        height: auto;
        border-radius: 2.5rem;
        padding: 5rem 2rem;

        > .row-1{
            flex-direction: column;
            align-items: center;

            > .number-item{
                width: 100%;
                align-items: center;
                margin-bottom: 5rem;

                > p.title{
                    font-size: 3rem;
                    margin-bottom: 4rem;
                }

                > p.value{
                    font-size: 12rem;
                }
            }

            > .percent-item{
                width: 100%;
                padding-top: 0;
                margin-bottom: 5rem;

                > p.title{
                    font-size: 1.5rem;
                    margin-bottom: 2.5rem;
                }

                > .indicator{
                    width: 14rem;
                    height: 14rem;

                    > p{
                        font-size: 2.5rem;
                    }
                }
            }
        }

        > .row-2{
            flex-direction: column;
            align-items: center;
            margin-bottom: 0;

            > .item{
                width: 65%;
                margin-bottom: 3rem;

                > .title{
                    margin-bottom: 1.8rem;
                    > p.label{
                        font-size: 1.8rem;
                    }

                    > p.value{
                        font-size: 2.3rem;
                    }
                }

                > .indicator{
                    height: 5px;
                }
            }
        }
    }
}