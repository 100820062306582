@import '../../shared.scss';

.home-landing-carousel {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;

    > .item{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        will-change: transform;

        > img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            top: 0;
            left: 0;

            &.mobile{
                display: none;
            }
        }
    }

    @include low-width{
        > .item{
            > img{
                &.desktop{
                    display: none;
                }

                &.mobile{
                    display: block;
                }
            }
        }
    }
}