@import '../../../shared.scss';

.gradient-image{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;

    > svg{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }

    
    > img{
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
    }
}